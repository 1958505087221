import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export const GlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  return (
    <div className=" pe-2">
    <div id="searchform">
      <input
        id="search-bar"
        autocomplete="off"
        type="text"
        className="form-control"
        placeholder="Search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <ul className="output" style={{ display: "none" }}></ul>
      <button className="btn-sea" type="submit">
        <i className="fa fa-search sea_ic bg_none"></i>
      </button>
    </div>
  </div>
  );
};
