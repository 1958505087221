import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./components/Abouts";
import ActRoles from "./components/Act-roule";
import Directorates from "./components/Directorates";
import SchoolEducation from "./components/School-Education";
import MatriculationSchools from "./components/Matriculation-Schools";
import SamagaShiksha from "./components/Samaga-Shiksha";
import GovermentExamination from "./components/Government-Examination";
import NonFormalAdultEducation from "./components/Non-Formal-Adult-Education";
import EnnumEzhuthum from "./components/Ennum-ezhuthum";
import Gos from "./components/Gos";
import Home from "./components/Home";
import KnowYourEmis from "./components/Know-your-emis";
import Magazine from "./components/Magazine";
import PressRelease from "./components/Press-release";
import RightEducation from "./components/Right-to-education";
import Scert from "./components/Scert";
import Layout from "./Layout/Layout";
import FMSProgram from "./components/FMS-Program";
import FMSLogin from "./components/FMS-Login";
import FMSOTP from "./components/FMS-OTP";
import FMSNewUser from "./components/FMS-Newuser";
import MagazinePayment from "./components/Magazine-payment";
import Pages from "./components/Pages";
import KnowYourEmisOtp from "./components/Know-your-emis-otp";
import KnowYourEmisList from "./components/KnowYourEmisList";
import FMSApplication from "./components/FMS-Apl";
import PageNotFound from "./components/PageNotFound";
import Blog from "./components/Blogs";
import Blog_detail from "./components/Blogs-detail.js";

const ReactRoutes = () => {
  return (
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/directorates" element={<Directorates />} />
          <Route path="/school_education" element={<SchoolEducation />} />
          <Route path="/matriculation_schools" element={<MatriculationSchools />} />
          <Route path="/samaka_shiksha" element={<SamagaShiksha />} />
          <Route path="/government_examinations" element={<GovermentExamination />} />
          <Route path="/non_formal_adult" element={<NonFormalAdultEducation />} />
          <Route path="/scert" element={<Scert />} />
          <Route path="/ennum_ezhuthum" element={<EnnumEzhuthum />} />
          <Route path="/magazine" element={<Magazine />} />
          <Route path="/magazine_payment" element={<MagazinePayment />} />
          <Route path="/act_rules_codes" element={<ActRoles />} />
          <Route path="/right_to_education" element={<RightEducation />} />
          <Route path="/gos" element={<Gos />} />
          <Route path="/press_release" element={<PressRelease />} />
          <Route path="/press_release" element={<PressRelease />} />
          <Route path="/know-your-emis" element={<KnowYourEmis />} />
          <Route path="/know-your-emis-otp" element={<KnowYourEmisOtp />} />
          <Route path="/know-your-emis-list" element={<KnowYourEmisList />} />
          <Route path="/FMS_program" element={<FMSProgram />} />
          <Route path="/FMS_application" element={<FMSApplication />} />
          <Route path="/FMS_login" element={<FMSLogin />} />
          <Route path="/FMS_otp" element={<FMSOTP />} />
          <Route path="/FMS_newuser" element={<FMSNewUser />} />
          <Route path="/pages/:slug" element={<Pages />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog_detail" element={<Blog_detail />} />
          <Route path="/blog_detail/:slug" element={<Blog_detail />} />
        </Route>

        <Route path="*" element={<PageNotFound/>} />
        <Route path="/404" element={<PageNotFound/>} />
      </Routes>
    </div>
  );
};

export default ReactRoutes;
