import React from "react";
import { navigationData } from "../constants/Services/Api/api";

const Pages = () => {
  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 7);
        if (finder?.id) {
          // getTemplate(finder?.slug)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  

  return <div>Pages</div>;
};

export default Pages;
