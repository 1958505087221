import httpClient from "../../HttpClient/httpClient";
import PrepareResponse from "./prepareResponse";
import HandleException from "./handleException";
import axios from "axios";

//  POST MATHEOD

export const post = async (url, data) => {
  try {
    let res = await httpClient.post(url, data);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

export const post_url = async (url, data) => {
  try {
   let headers = {
      // "Content-type": "multipart/form-data",
      Accept: "application/json",
    }
    let res = await axios.post(url, data, {headers:headers});
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

// GET METHOD

export const get = async (url, data) => {
  try {
    let res = await httpClient.get(url);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

export const get_url = async (url, data) => {
  try {
    let headers = {
      // "Content-type": "multipart/form-data",
      Accept: "application/json",
    }
    let res = await axios.get(url, {headers:headers});
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

// DELETE METHOD

export const deletes = async (url, data) => {
  try {
    let res = await httpClient.delete(url);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};
