import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Layout/Footer";
import toast from "react-hot-toast";
import { knowEmisOTP } from "../constants/Services/Api/api";

const KnowYourEmis = () => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [number, setNumber] = useState("");
  const [thirukural_img, setThirukural_img] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleCaptchaSubmit = () => {
    if (userInput === captchaText) {
      getOTP();
    } else {
      // alert("Incorrect");
      toast.error("Invalid Captcha");
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      initializeCaptcha(ctx);
    }
  };

  const getOTP = () => {
    let payload = {
      records: {
        mob: number,
      },
    };
    if (number?.length < 10) {
      toast.error("Please Enter Mobile Number");
    } else {
      setBtn(true);
      knowEmisOTP(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            navigate("/know-your-emis-otp", { state: { number: number } });
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div className="main">
        {/* <!-- know your emis starts --> */}
        <section className="know_emis mar_100">
          <div className="container">
            <div className="d-flex-jc-ac">
              <div className="col-lg-6 col-12 d-lg-block d-none">
                <div className="know_emis">
                  <img src="/assets/img/know_emis_login.png" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="know_login">
                  <h2>Know your EMIS</h2>
                  <p>
                    Unlock a world of education with a single click! Please
                    login in to your account.
                  </p>
                  <div action="">
                    <div className="">
                      <label for="">Enter Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        name="mobile"
                        placeholder="+91 "
                        id="phone"
                        maxLength={10}
                        required
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          if (e.target.value.length !== 10) {
                            e.target.setCustomValidity("invalid Number");
                          } else if (e.target.value.length == 10) {
                            e.target.setCustomValidity("");

                            setNumber(e.target.value);
                          }
                        }}
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        onChange={(e) => setNumber(e.target.value)}
                        value={number}
                      />
                    </div>
                    <div className="">
                      <label for="">Enter Captcha</label>
                      <div className="d-flex flex-wrap">
                        <div className="col-sm-6 col-12 pe-2">
                          <input
                            type="text"
                            className="form-control"
                            name="c"
                            placeholder=""
                            id="user-input"
                            value={userInput}
                            onChange={handleUserInputChange}
                          />
                        </div>
                        <div className="col-sm-6 col-12">
                          <div className="captcha_sec">
                            <h5>
                              <canvas
                                ref={canvasRef}
                                width="200"
                                height="50"
                              ></canvas>
                            </h5>

                            <button
                              id="reload-button"
                              className="btn btn-primary"
                              onClick={() =>
                                initializeCaptcha(
                                  canvasRef.current.getContext("2d")
                                )
                              }
                            >
                              <i class="bx bx-revision"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="container">
                      <div className="wrapper">
                        <canvas
                          ref={canvasRef}
                          width="200"
                          height="70"
                        ></canvas>
                        <button
                          id="reload-button"
                          onClick={() =>
                            initializeCaptcha(
                              canvasRef.current.getContext("2d")
                            )
                          }
                        >
                          Reload
                        </button>
                      </div>
                      <input
                        type="text"
                        id="user-input"
                        placeholder="Enter the text in the image"
                        value={userInput}
                        onChange={handleUserInputChange}
                      />

                      <button id="submit-button" onClick={handleCaptchaSubmit}>
                        Submit
                      </button>
                    </div> */}

                    <div
                      className="btn_login mt_15"
                      onClick={() => {
                        if (btn == false) {
                          handleCaptchaSubmit();
                        }
                      }}
                    >
                      <input type="submit" value="Sent OTP" />
                    </div>
                  </div>

                  {/* <h6>
                    Don’t have an account? <a href="">Sign Up for Free</a>
                  </h6> */}
                </div>
              </div>
              <div className="col-lg-6 col-12 d-lg-none d-block">
                <div className="know_emis">
                  <img src="/assets/img/know_emis_login.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- know your emis ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default KnowYourEmis;
