import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { scertChildData, viewBookPaths } from "../constants/Services/Api/api";
import Footer from "../Layout/Footer";

const Scert = () => {
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [mediumList, setMediumList] = useState([]);
  const [tremsList, setTermsList] = useState([]);
  const [books, setBooks] = useState([]);

  const [classID, setClassID] = useState("");
  const [subjectID, setSubjectID] = useState("");
  const [mediumID, setMediumID] = useState("");
  const [tremsID, setTermsID] = useState("");
  const [btn, setBtn] = useState(false);

  const languages = localStorage?.getItem("language");

  

  const getList = (id, type) => {
    let payload = {
      parent_id: id ? id : null,
    };
    scertChildData(payload)
      .then((responce) => {
        let res = Array.isArray(responce) ? responce : [];
        let temp = [];
        res?.map((item, ind) => {
          let obj = { ...item };
          if (localStorage.getItem("language") == 2) {
            obj.name = item?.name2;
          } else {
            obj.name = item?.name1;
          }

          temp.push(obj);
        });

        if (type == 1) {
          setSubjectList(temp);
          setMediumList([]);
          setTermsList([]);
          setClassID(id);
          setSubjectID("");
          setMediumID("");
          setTermsID("");
        } else if (type == 2) {
          setMediumList(temp);
          setTermsList([]);
          setSubjectID(id);
          setMediumID("");
          setTermsID("");
        } else if (type == 3) {
          setTermsList(temp);
          setMediumID(id);
          setTermsID("");
          getBookData(classID, subjectID, id);
        } else {
          setClassList(temp);
          setClassID("");
          setSubjectID("");
          setMediumID("");
          setTermsID("");
        }
        setBooks([]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const getBookData = (class_id, subject_id, medium_id) => {
    if (class_id && subject_id && medium_id) {
      let payload = {
        class_id: class_id,
        subject_id: subject_id,
        medium_id: medium_id,
      };

      if (tremsID) {
        payload.term_id = tremsID;
      }

      setBtn(true);
      viewBookPaths(payload)
        .then((responce) => {
          setBtn(false);
          let res = Array.isArray(responce) ? responce : [];
          let temp = [];
          res?.map((item, ind) => {
            let obj = { ...item };
            if (localStorage.getItem("language") == 2) {
              obj.name = item?.tamil_title;
            } else {
              obj.name = item?.eng_title;
            }

            temp.push(obj);
          });

          setBooks(temp);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1 style={{ "font-size": "30px" }}>
                {" "}
                {languages == 2
                  ? "மாநில கல்வியியல் ஆராய்ச்சி மற்றும் பயிற்சி நிறுவனம் தமிழ்நாடு"
                  : "State Council of Educational Research and Training (SCERT)"}
                <br />
                {languages == 2 ? "தமிழ்நாடு" : "Tamil Nadu"}
              </h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- scert section starts --> */}

        <section className="scert_section mar_100">
          <div className="container">
            <div className="scert_form mt_70">
              <h4>
                {languages == 2
                  ? "தேவையான புத்தகங்களை தெரிவு செய்யவும்"
                  : "Please choose the appropriate books"}{" "}
              </h4>
              <div>
                <div className="d-flex-jc">
                  <div className="col-md-4 col-sm-6 col-12 mt_20 pe-md-5 pe-0">
                    <select
                      className="form-control form-select"
                      name="service"
                      required
                      onChange={(e) => getList(e.target.value, 1)}
                      value={classID}
                    >
                      <option value={""} selected disabled>
                        {languages == 2 ? "வகுப்பு" : "Select Class"}
                      </option>
                      {classList?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 mt_20 pe-md-5 pe-0">
                    <select
                      className="form-control form-select"
                      name="service"
                      required
                      onChange={(e) => getList(e.target.value, 2)}
                      value={subjectID}
                    >
                      <option value={""} selected disabled>
                        {languages == 2 ? "பாடம்" : "Select Subject"}
                      </option>
                      {subjectList?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12 mt_20 pe-md-5 pe-0">
                    <select
                      className="form-control form-select"
                      name="service"
                      required
                      onChange={(e) => getList(e.target.value, 3)}
                      value={mediumID}
                    >
                      <option value={""} selected disabled>
                        {languages == 2 ? "மொழி வழி" : "Select Medium"}
                      </option>
                      {mediumList?.map((item, index) => {
                        return (
                          <option value={item?.id} key={index}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              {books?.length > 0 && (
                <div className="scert_table">
                  <div className="d-flex flex-wrap justify-content-lg-end justify-content-center gap-2">
                  <div className="text-end">
                    <div className="btn_red">
                      <a href="https://d1wpyxz35bzzz4.cloudfront.net/tnschools/y3I0EAPVJMTFPIAJkmbPVBJsiiurJw.pdf" target={'_blank'}>Instructions for PC</a>
                    </div>
                  </div>
                  <div className="text-end">
                    <div className="btn_red">
                      <a href="https://d1wpyxz35bzzz4.cloudfront.net/tnschools/HGvmxINU5CdpMUC2hNEFxbdqksyOVI.pdf" target={'_blank'}>Instructions for Mobile</a>
                    </div>
                  </div>
                  <div className="text-end">
                    <div className="btn_red">
                      <a href="https://d1wpyxz35bzzz4.cloudfront.net/tnschools/49uGuVjgL0AjrdbzIOqRrJKykMu32y.pdf" target={'_blank'}>Disclaimer</a>
                    </div>
                  </div>
                  </div>

                  <div className="table_section mt_30">
                    <table cellspacing="0">
                      <tr>
                        <th>Book Title</th>
                        <th>Textbook (pdf)</th>
                        <th>Access Digital Textbook (ADT-EPub)</th>
                        <th>E- Book</th>
                      </tr>
                      {books?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                className="table_input p-2"
                                readOnly
                                value={item?.name}
                              />
                            </td>
                            <td>
                              {item?.book_path ? (
                                <a
                                  href={item?.book_path}
                                  target={"_blank"}
                                  style={{ cursor: "pointer" }}
                                >
                                  Download Pdf{" "}
                                  <i className="ri-download-2-line"></i>
                                </a>
                              ) : (
                                "Coming Soon"
                              )}
                            </td>
                            <td>
                              {item?.epub_path ? (
                                <a
                                  href={item?.epub_path}
                                  target={"_blank"}
                                  style={{ cursor: "pointer" }}
                                >
                                  Download EPub{" "}
                                  <i className="ri-download-2-line"></i>
                                </a>
                              ) : (
                                "Coming Soon"
                              )}
                            </td>
                            <td>
                            {item?.html_path ? (
                                <a
                                  href={item?.html_path}
                                  target={"_blank"}
                                  style={{ cursor: "pointer" }}
                                >
                                  Download{" "}
                                  <i className="ri-download-2-line"></i>
                                </a>
                              ) : (
                                "Coming Soon"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              )}

              <div className="scert_pdf mt_50">
                <div className="d-flex-jc-ac">
                  <div className="col-md-8 pe-3">
                    <p>
                      {languages == 2
                        ? "மின்னுரு புத்தங்களை (ADT) பற்றிய உங்கள் கருத்துகளை இங்கே பகிரவும்"
                        : "Please share your feedback on ADT here!"}
                    </p>
                    <div className="btn_blue mt-5 pe-3">
                      <a href="https://forms.gle/Ugqmo9Wx3Gg2dpH2A" target={"_blank"}>
                        Give Feedback
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      className="w-100"
                      src="./assets/img/person-with-books-digital-art-style-education-day.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- scert section ends --> */}

        {/* <!-- thirukural starts --> */}

        <section className="thirukural_section mt_70">
          <div className="thiru_img d-lg-block d-none">
            <img src="/assets/img/thirukural_section.webp" alt="" />
          </div>
          <div className="thiru_img d-lg-none d-block">
            <img src="/assets/img/thirukural_section_md.webp" alt="" />
          </div>
        </section>

        {/* <!-- thirukural ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default Scert;
