import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function PageNotFound() {
    const navigation = useNavigate()
    
  return (
    <>
    <section>
        <div className='container'>
            <div className='d-flex justify-content-center align-items-center vh-100'>
                <div className='text-center'>
                <img src='/assets/img/404.jpg' className='mb-2 w-100' />
                <h5>Page Not Found</h5>
                <button type='button' onClick={() => navigation('/') } className='btn btn-primary mt-2'>Home</button>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
