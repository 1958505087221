import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layout/Footer";
import {
  magazineGet,
  magazineOTPVerify,
  magazinePublish,
  magazineSendOTP,
} from "../constants/Services/Api/api";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import moment from "moment/moment";
import CcPaymentForm from "./MagazinePay";

const MagazinePayment = () => {
  const languages = localStorage.getItem("language");
  const [magazineList, setMagazineList] = useState([]);
  const [thirukural_img, setThirukural_img] = useState("");
  const [number, setNumber] = useState("");
  const [total_price, setTotal_price] = useState(0);
  const [step, setStep] = useState(1);
  const [btn, setBtn] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [otp, setOtp] = useState("");

  const totalSteps = 4;

  const nextStep = () => {
    if (step < totalSteps) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const getMagazineList = () => {
    magazineGet()
      .then((res) => {
        let temp = [];
        res?.magazine_list?.map((item, ind) => {
          let obj = { ...item };
          if (languages == 2) {
            obj.name_ = item?.tName;
            if (item?.notes !== 0) {
              obj.grade_ = item?.notes_tamil;
            } else {
              obj.grade_ = item?.grade_tamil;
            }
          } else {
            obj.name_ = item?.name;
            if (item?.notes !== 0) {
              obj.grade_ = item?.notes;
            } else {
              obj.grade_ = item?.grade;
            }
          }
          obj.quantity = 0;
          obj.amount = 0;
          temp.push(obj);
        });

        setMagazineList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMagazineList();
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [flot_no, setFlot_no] = useState("");
  const [building_no, setBuilding_no] = useState("");
  const [streetName, setStreetName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [pintcode, setPintcode] = useState("");
  const [verify, setVerify] = useState(false);
  const [order_details, setorder_details] = useState({});
  const [access_code, setAccess_code] = useState("");
  const [encryptData, setEncryptData] = useState("");

  const handleQty = (item, ind, type) => {
    let temp = [...magazineList];
    let obj = { ...item };

    if (type == "decrement") {
      let qty = +item?.quantity - 1;
      obj.quantity = +qty;
      obj.amount = +item?.uniCost * +qty;
    } else {
      let qty = +item?.quantity + 1;
      obj.quantity = +qty;
      obj.amount = +item?.uniCost * +qty;
    }
    temp[ind] = obj;

    if (item?.itemId == 8) {
      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?.itemId != 8) {
          temp[i]["quantity"] = 0;
          temp[i]["amount"] = 0;
        }
      }
    } else {
      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?.itemId == 8) {
          temp[i]["quantity"] = 0;
          temp[i]["amount"] = 0;
        }
      }
    }

    const result = temp.reduce((sum, { amount }) => sum + amount, 0);
    setTotal_price(result);
    setMagazineList(temp);
  };

  const getOTP = () => {
    let payload = {
      records: {
        studentDetails: {
          otp_purpose_id: 13,
          triggered_by_username: "",
          triggered_by_usertype: "",
          triggered_for_username: number,
          triggered_for_usertype: "",
          triggered_for_name: name,
          triggered_schoolname: "",
        },
        otpTemplate: {
          TemplateId: "1707171506384943024",
          MobileNo: number,
          TemplateMsg:
            "உங்கள் மொபைல் எண்ணைச் சரிபார்க்க OTP {#var#} ஐப் பயன்படுத்தவும் - தமிழ்நாடு பள்ளிக் கல்வித் துறை",
          var: {
            var1: "",
          },
        },
      },
    };

    if (number?.length < 10) {
      toast.error("Please Enter Mobile Number");
    } else {
      setBtn(true);
      magazineSendOTP(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success("OTP Sended Successfully");
            handleShow();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const handleVerifyOTP = () => {
    if (otp?.length < 6) {
      toast.error("Please Enter OTP");
    } else {
      let payload = {
        OTP: otp,
        UsrId: number,
      };

      setBtn(true);
      magazineOTPVerify(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            handleClose();
            setVerify(true);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // console.log("magazineList", magazineList);

  const handlePayment = () => {
    if (
      name?.length == 0 ||
      email?.length == 0 ||
      flot_no?.length == 0 ||
      building_no?.length == 0 ||
      streetName?.length == 0 ||
      landmark?.length == 0 ||
      city?.length == 0 ||
      district?.length == 0 ||
      pintcode?.length == 0
    ) {
      toast.error("Please Fil All Details");
    } else {
      let payloads = new FormData();
      payloads.append("totalAmt", total_price);
      payloads.append("records[idxId]", "");
      payloads.append("records[firstName]", name);
      payloads.append("records[email]", email);
      payloads.append("records[mobiNo]", number);
      payloads.append("records[otp]", otp);
      payloads.append(
        "records[mobiVerifyDate]",
        moment(new Date()).format("DD-MM-YYYY hh:mm:ss")
      );
      payloads.append("records[address1]", `${flot_no}, ${building_no}`);
      payloads.append("records[address2]", streetName);
      payloads.append("records[areaName]", landmark);
      payloads.append("records[cityName]", city);
      payloads.append("records[distId]", district);
      payloads.append("records[pinCode]", pintcode);

      magazineList?.map((item, ind) => {
        if (item?.amount > 0) {
          payloads.append(`payload[cartItems][${ind}][itemCode]`, item?.itemId);
          payloads.append(`payload[cartItems][${ind}][uniCost]`, item?.uniCost);
          payloads.append(`payload[cartItems][${ind}][subTot]`, item?.amount);
          payloads.append(`payload[cartItems][${ind}][taxAmt]`, 0);
          payloads.append(`payload[cartItems][${ind}][uniCnt]`, item?.uniCost);
          payloads.append(`payload[cartItems][${ind}][name]`, item?.name);
          payloads.append(`payload[cartItems][${ind}][qty]`, item?.quantity);
          payloads.append(`payload[cartItems][${ind}][totAmt]`, item?.amount);
        }
      });

      setBtn(true);
      magazinePublish(payloads)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            setorder_details(res);
            setAccess_code(res?.order_details?.Acode);
            setEncryptData(res?.order_details?.check_sum);
            
            nextStep();
          }
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
        });
    }
  };

  useEffect(() => {
    if(access_code && encryptData){
      document.getElementById('ccPayment').submit();
    }
  },[access_code, encryptData])

  return (
    <>
      {access_code && encryptData && (
        <CcPaymentForm accessCode={access_code} encryptData={encryptData} />
      )}
      <div className="main">
        <Modal centered show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Verification Code</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>We sent a code to xxxxxx{number?.slice(-4)}</p>
            <div className="mt-3 mb-3 d-flex justify-content-center">
              <input
                type="number"
                className="form-control w-50"
                name="otp"
                placeholder=""
                id="phone"
                required
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                maxLength={6}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 6) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 6) {
                    e.target.setCustomValidity("");

                    setOtp(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
            </div>
            <div className="px-2">
              <button
                onClick={() => handleVerifyOTP()}
                className="a_blue"
                disabled={btn}
              >
                Verify
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>Magazine</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* magazin new */}

        <section className="magazine_payment mar_100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center p-0 mt-3 mb-2">
                <div className="card px-3 pt-4 pb-0 mt-3 mb-3">
                  <h2 id="heading">Subscribe your Magazine</h2>
                  <p>Follow the Steps</p>
                  <div id="msform">
                    <ul id="progressbar">
                      <li class="active" id="account">
                        <strong>Step 1</strong>
                        <br />
                        <small>Magazine</small>
                      </li>
                      <li className={step > 1 ? "active" : ""} id="personal">
                        <strong>Step 2</strong>
                        <br /> <small>Address</small>
                      </li>
                      <li className={step > 2 ? "active" : ""} id="payment">
                        <strong>Step 3</strong>
                        <br /> <small>Summary</small>
                      </li>
                      <li className={step > 3 ? "active" : ""} id="confirm">
                        <strong>Step 4</strong>
                        <br /> <small>Receipt</small>
                      </li>
                    </ul>

                    <br />
                    {step === 1 && (
                      <div class="form-card mt-4">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="fs-title">Magazine:</h2>
                          </div>
                          <div class="col-5">
                            <h2 class="steps">Step 1 - 4</h2>
                          </div>
                        </div>
                        <div class="order_magazine">
                          {magazineList?.map((item, ind) => {
                            return (
                              <div class="d-flex-jc-ac mt-4" key={ind}>
                                <div class="col-lg-6">
                                  <div class="d-flex align-items-center">
                                    <div class="order_book">
                                      <img
                                        src={item?.bucket_url}
                                        // src={"./assets/img/PudhuOonjal.png"}
                                        alt=""
                                      />
                                    </div>
                                    <div class="order_bocon">
                                      <h3>{item?.name_}</h3>
                                      <p>{item?.grade_}</p>
                                      <p>Rate: ₹{item?.uniCost}</p>
                                      <h6>No Of Month: 10</h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-3 text-center">
                                  <div className="quantity-selector w-100">
                                    <button
                                      onClick={() => {
                                        if (item?.quantity > 0) {
                                          handleQty(item, ind, "decrement");
                                        }
                                      }}
                                    >
                                      -
                                    </button>
                                    <span>{item?.quantity}</span>
                                    <button
                                      onClick={() => {
                                        if (item?.quantity < +item?.maxCnt) {
                                          handleQty(item, ind, "increment");
                                        }
                                      }}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <div class="col-lg-3 text-end">
                                  <h4>₹ {item?.amount}</h4>
                                </div>
                              </div>
                            );
                          })}
                          <div class="payment_am mt-4">
                            <div class="d-flex justify-content-between pt-4">
                              <div class="col-md-9">
                                <h4>Payment Details</h4>
                              </div>
                              <div class="col-md-3">
                                {/* <div class="amount_info mb-3">
                                  <div class="d-flex">
                                    <div class="col-6">
                                      <h6 class="bold_txt">Sub total </h6>
                                    </div>
                                    <div class="col-6 text-end">
                                      <h6>₹800</h6>
                                    </div>
                                  </div>
                                </div> */}
                                <div class="amount_info mb-3">
                                  <div class="d-flex">
                                    <div class="col-6">
                                      <h6 class="bold_txt">Total Price </h6>
                                    </div>
                                    <div class="col-6 text-end">
                                      <h6>₹{total_price}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="next action-button"
                          onClick={nextStep}
                        >
                          Next
                        </button>
                      </div>
                    )}
                    {step === 2 && (
                      <div class="form-card">
                        <div class="row">
                          <div class="col-7">
                            <h2 class="fs-title">Address:</h2>
                          </div>
                          <div class="col-5">
                            <h2 class="steps">Step 2 - 4</h2>
                          </div>
                        </div>
                        <div class="d-flex-jc">
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="Name"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="email"
                              name="fname"
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="House / Flat No"
                              onChange={(e) => setFlot_no(e.target.value)}
                              value={flot_no}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="Building Name & Block No"
                              onChange={(e) => setBuilding_no(e.target.value)}
                              value={building_no}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="Street Name"
                              onChange={(e) => setStreetName(e.target.value)}
                              value={streetName}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="Landmark Area(village)Name"
                              onChange={(e) => setLandmark(e.target.value)}
                              value={landmark}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="City / Town"
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="text"
                              name="fname"
                              placeholder="District"
                              onChange={(e) => setDistrict(e.target.value)}
                              value={district}
                            />
                          </div>
                          {/* <div class="col-lg-6 col-12 pe-3">
                            <select
                              class="form-control form-select"
                              name="service"
                              required
                              onChange={(e)=>setDistrict(e.target.value)}
                              value={district}
                            >
                              <option selected disabled>
                                Select District
                              </option>
                              <option value="Class I">Class I</option>
                              <option value="Class II">Class II</option>
                              <option value="Class III">Class III</option>
                              <option value="Class IV">Class IV</option>
                              <option value="Class V">Class V</option>
                              <option value="Class VI">Class VI</option>
                              <option value="Class VII">Class VII</option>
                              <option value="Class VIII">Class VIII</option>
                              <option value="Class IX">Class IX</option>
                              <option value="Class X">Class X</option>
                              <option value="Class XI">Class XI</option>
                              <option value="Class XII">Class XII</option>
                            </select>
                          </div> */}
                          <div class="col-lg-6 col-12 pe-3">
                            <input
                              class="form-control"
                              type="number"
                              name="fname"
                              placeholder="Pin code"
                              onChange={(e) => setPintcode(e.target.value)}
                              value={pintcode}
                            />
                          </div>
                          <div class="col-lg-6 col-12 pe-3 d-flex gap-2">
                            <input
                              class="form-control"
                              type="number"
                              name="fname"
                              placeholder="Mobile Number"
                              maxLength={10}
                              onInput={(e) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(
                                    0,
                                    e.target.maxLength
                                  );
                                if (e.target.value.length !== 10) {
                                  e.target.setCustomValidity("invalid Number");
                                } else if (e.target.value.length == 10) {
                                  e.target.setCustomValidity("");

                                  setNumber(e.target.value);
                                }
                              }}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              onChange={(e) => setNumber(e.target.value)}
                              value={number}
                            />
                            <div>
                              {verify ? (
                                <button
                                  style={{ width: "100%" }}
                                  className="a_blue text-nowrap"
                                >
                                  Verified
                                </button>
                              ) : (
                                <button
                                  // href="/#/FMS_otp"
                                  onClick={() => getOTP()}
                                  style={{ width: "100%" }}
                                  className="a_blue text-nowrap"
                                  disabled={btn}
                                >
                                  Get OTP
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <button
                            className="previous action-button-previous"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                          <button
                            className="next action-button"
                            // onClick={nextStep}
                            disabled={btn}
                            onClick={() => handlePayment()}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 3 && (
                      <div>
                        <h2>Summary</h2>
                        <p>Review your order</p>
                        <div className="mt-2 d-flex justify-content-center">
                          <h2>Order ID : {order_details?.orderId}</h2>
                        </div>
                        <p className="text-center">Order Generated</p>
                        <div className="d-flex justify-content-between">
                          <button
                            className="previous action-button-previous"
                            onClick={prevStep}
                          >
                            Previous
                          </button>
                          <button
                            className="next action-button"
                            onClick={nextStep}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                    {step === 4 && (
                      <div>
                        <h2>Success!</h2>
                        <p>You have successfully subscribed</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default MagazinePayment;
