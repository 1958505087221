import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import TopBar from "../Layout/TopBar";
import Accordion from "react-bootstrap/Accordion";
import { blogLIstData } from "../constants/Services/Api/api";
import moment from "moment";
// import {
//   navigationData,
//   templeteDetailsData,
// } from "../constants/Services/Api/api";

const Blog = () => {
  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [BlogList, setBlogList] = useState([]);
  const [thirukural_img, setThirukural_img] = useState("");
  const [thirukural, setThirukural] = useState("");



  const handleBlogList = () =>{
    blogLIstData().then(res =>  {
      let list = res?.map(item =>  {
        if(languages == 2){
          return {...item, title : item?.tamil_title, desc : item?.tamil_description}
        }
        return {...item, title : item?.eng_title, desc : item?.eng_description}
      })
      setBlogList(list)
    }).catch(err => {
      console.log('err', err);
      
    })
  }

  console.log('BlogListBlogList', BlogList);
  

  useEffect(() => {
   handleBlogList();
  },[])


  return (
    <>
      <div className="main">
        {/* <TopBar setNavigationDataList={setNavigationDataList}/> */}
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={'/assets/img/blog_img.png'}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>Blog</h1>
             
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* blog list starts */}

        <section className="blog_section mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>Blogs</h2>
            </div>

            <div className="d-flex-jc">
              {
                BlogList?.length > 0 ? 

                BlogList?.map((data, i) => (
                  <div className="col-lg-4 col-sm-6 col-12 mt_30" key={i}>
                    <div className="blog_card">
                      <div className="blog_thumb_img">
                        <img src={data?.blog_image} alt="" />
                        <div className="blog_thdate">
                          <span><i class="fa-regular fa-calendar"></i> {moment(data?.updated_at).format("MMMM Do YYYY")}</span>
                        </div>
                      </div>
                      <h3>
                        {data?.title}
                      </h3>
                      <p dangerouslySetInnerHTML={{__html:data?.desc}}></p>
                      <div className="d-flex justify-content-between align-item-center my-4">
                        <div className="btn_new">
                          <Link to={`/Blog_detail/${data?.slug}`}>Read more</Link>
                        </div>
                        <div className="read_time">
                          <span>5 Min Read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                 )) : <h4>No Data Found!</h4>
              }
              {/* <div className="col-lg-4 col-sm-6 col-12 mt_30">
                <div className="blog_card">
                  <div className="blog_thumb_img">
                    <img src="/assets/img/blog_img.png" alt="" />
                    <div className="blog_thdate">
                      <span><i class="fa-regular fa-calendar"></i> December 9, 2025</span>
                    </div>
                  </div>
                  <h3>
                    The Evolution of Education in Tamil Nadu: A Journey Through
                    Time
                  </h3>
                  <p>
                    How Tamil Nadu’s education system has transformed over the
                    years, from ancient gurukuls to modern smart classrooms.
                  </p>
                  <div className="d-flex justify-content-between align-item-center my-4">
                    <div className="btn_new">
                      <a href="/#/Blog_detail">Read more</a>
                    </div>
                    <div className="read_time">
                      <span>5 Min Read</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* blog list ends */}

        {/* <!-- thirukural starts --> */}

        {/* <section className="thirukural_section ">
          <div className="thiru_img">
            <img
              src={
                thirukural_img
                  ? thirukural_img
                  : "/assets/img/thirukural_section.webp"
              }
              alt=""
            />
          </div>
          <div className="thiru_img d-lg-none d-block">
            <img src="/assets/img/thirukural_section_md.webp" alt="" />
          </div>
        </section> */}

<section className="th_section">
          <div className="container">
            <div className="th_flex">
              <div className="col-md-4">
                <div className="thi_img">
                  <img src="/assets/img/thiruvaluvar_ic.webp" alt="" />
                </div>
              </div>
              <div className="col-md-8 ps-md-3 ps-0">
                <div className="th_cont">
                  <span>{thirukural?.THIRUKURAL_TITLE}</span>
                  <h3 dangerouslySetInnerHTML={{__html:thirukural?.THIRUKURAL}}></h3>
                  <p>{thirukural?.THIRUKURAL_DESC}</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* <!-- thirukural ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural={setThirukural} setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default Blog;