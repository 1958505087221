import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import { navigationData, templeteDetailsData } from "../constants/Services/Api/api";

const RightEducation = () => {
  const location = useLocation()
  const languages = localStorage.getItem("language")
  const[navigationDataList, setNavigationDataList]=useState({})
  const [thirukural_img, setThirukural_img]=useState("")

      const getTemplate=(navigation_slug, type)=>{
        let payload = new FormData()
        payload.append('navigation_slug', navigation_slug)
        templeteDetailsData(payload).then((res)=>{
          let data = res?.response
          let temp = {...data}
    
          if(data?.banner_json){
            let banner_json = {...data?.banner_json}        
            if(languages == 2){
              banner_json.title = data?.banner_json?.tamil_title
            }else{
              banner_json.title = data?.banner_json?.eng_title
            }
            temp.banner_json = banner_json
           }
           
            // TAB 2
  
            if(data?.overview_json){
              let banner_json = {...data?.overview_json}        
              if(languages == 2){
                banner_json.title = data?.overview_json?.tamil_title
                banner_json.desc = data?.overview_json?.tamil_desc
              }else{
                banner_json.title = data?.overview_json?.eng_title
                banner_json.desc = data?.overview_json?.eng_desc
              }              
              temp.overview_json = banner_json
             }

             if(data?.rte_acts_json){
              let banner_json = {...data?.rte_acts_json}        
              if(languages == 2){
                banner_json.act = data?.rte_acts_json?.tamil_act
              }else{
                banner_json.act = data?.rte_acts_json?.eng_act
              }              
              temp.rte_acts_json = banner_json
             }

             if(data?.download_rte_json){
              let banner_json = {...data?.download_rte_json}        
              if(languages == 2){
                banner_json.title = data?.download_rte_json?.tamil_title
                banner_json.desc = data?.download_rte_json?.tamil_desc
              }else{
                banner_json.title = data?.download_rte_json?.eng_title
                banner_json.desc = data?.download_rte_json?.eng_desc
              }              
              temp.download_rte_json = banner_json
             }

             //  console.log('temp', temp);  
          setNavigationDataList(temp)
        }).catch((err)=>{
          console.log('err', err);
          
        })
      }
      
          const getNavigation=()=>{
            let payload={    
            }
            navigationData(payload).then((res)=>{ 
              let temp = []
              res?.map((item, ind)=>{
                item?.child_navigations?.map((chid, chind)=>{
                  temp.push(chid)
                })
                
                temp.push(item)
              })
              let finder = temp?.find((i)=>i?.template_type == 6) 
              if(finder?.id){
                getTemplate(finder?.slug)
              }     
            }).catch((err)=>{
              console.log('err', err);
              
            })
          }
        
        useEffect(()=>{ 
          if(location?.state?.data?.slug){
           getTemplate(location?.state?.data?.slug)
          }else{
            getNavigation()
          }
        },[])

        // console.log('navigationDataList', navigationDataList);
  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>{navigationDataList?.banner_json?.title}</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- right to education starts --> */}

        <section className="right_education mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>{navigationDataList?.overview_json?.title}</h2>
              <p dangerouslySetInnerHTML={{__html:navigationDataList?.overview_json?.desc}}>
              </p>
            </div>

            <div className="scert_pdf mt_30">
              <div className="d-flex flex-wrap justify-content-center">
                <div className="scert_down pe-3">
                  <a href={navigationDataList?.overview_json?.application_path} target={"_blank"}>
                    <div className="download_card">
                      <div className="d-flex align-items-center">
                        <img src="/assets/img/pdf.png" alt="" />
                        <h6>RTE Admission Application</h6>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="scert_down pe-3">
                  <a href={`tel:${navigationDataList?.overview_json?.call_us}`}>
                    <div className="download_card">
                      <div className="d-flex align-items-center">
                        <img src="/assets/img/online-library.png" alt="" />
                        <h6>Call Us : {navigationDataList?.overview_json?.call_us}</h6>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="rte_provide mt_30">
              <ul className="mt_10">
              <li dangerouslySetInnerHTML={{__html:navigationDataList?.rte_acts_json?.act}}>
                </li>
              </ul>
            </div>

            <div className="right_rte mt_20 text-center">
              <h5>{navigationDataList?.download_rte_json?.title}</h5>
              <p dangerouslySetInnerHTML={{__html:navigationDataList?.download_rte_json?.desc}}>
              </p>
              <div className="btn_new mt_10 mb_20">
                <a href={navigationDataList?.download_rte_json?.document_path} target={"_blank"}>Download doucment</a>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- right to education ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img}/>
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default RightEducation;
