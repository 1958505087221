import React from "react";
import { Link } from "react-router-dom";

const SchoolEducation = () => {
  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>Directorate of School Education</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- directorates section starts --> */}

        <div className=" mar_100 directortes_section">
          <div
            id="elementary_education"
            className="tab-pane active dat_left holder mt-3"
          >
            <div className="container">
              <div className="title_section">
                <h2>About</h2>
                <p className="text_just">
                  Article 45 of the Indian Constitution mandated the State to
                  endeavor to provide, within a period of 10 years from the
                  commencement of the Constitution, free and compulsory
                  education for all children until they complete the age of 14
                  years.By the 86th Amendment Act 2002, Article 21A of the
                  Constitution was inserted declaring that right to education
                  for all children from the age of 6 -14 is the fundamental
                  right.
                </p>
                <p className="text_just">
                  {" "}
                  Elementary Education refers to the first phase of compulsory
                  education that children obtain from the age of 6 to 14 years.
                  It provides students the basic understanding of various
                  subjects and develops critical thinking skills which they
                  require throughout their lives.
                </p>
                <p className="text_just">
                  {" "}
                  In 2009 Parliament enacted the Right of Children to free and
                  compulsory Education Act 2009 (Act 35 of 2009) with the object
                  of free and compulsory education to all the children from the
                  age of 6 - 14 years. In order to implement the Right of
                  Children to Free and Compulsory Education (RTE) Act, 2009 and
                  to achieve the educational goals through Elementary Education,
                  the Government of Tamil Nadu is taken lot of initiatives and
                  introduced various welfare measures..
                </p>
              </div>

              <div className="d-flex-jc">
                <div className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3">
                  <div className="count_card">
                    <div className="count_icon">
                      <img src="/assets/img/student_studying.png" alt="" />
                    </div>
                    <h3>2550997</h3>
                    <p>Students Studying in Tamil Nadu</p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3">
                  <div className="count_card">
                    <div className="count_icon">
                      <img src="/assets/img/student_studying.png" alt="" />
                    </div>
                    <h3>108537</h3>
                    <p>Teacher Working in Tamil Nadu</p>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3">
                  <div className="count_card">
                    <div className="count_icon">
                      <img src="/assets/img/student_studying.png" alt="" />
                    </div>
                    <h3>31336</h3>
                    <p>Schools throughout Tamil Nadu.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="director_objectives mar_100">
              <div className="d-flex-jc-ac container">
                <div className="col-lg-6 col-12 pe-lg-5 pe-0 ">
                  <div className="title_section">
                    <h2>Objectives</h2>
                    <p>
                      Ensuring that students, parents, and educators can easily
                      access educational resources, updates, and opportunities
                      regardless of their location or background.
                    </p>
                  </div>
                  <div className="objective_list">
                    <ul>
                      <li>
                        <i className="fa-solid fa-square-check txt_darkbl mt-1 me-2"></i>{" "}
                        Ensuring100% access to all children
                      </li>
                      <li>
                        <i className="fa-solid fa-square-check txt_darkbl mt-1 me-2"></i>{" "}
                        Opening of new schools/ upgrading existing schools
                      </li>
                      <li>
                        <i className="fa-solid fa-square-check txt_darkbl mt-1 me-2"></i>{" "}
                        Opening of residential schools in sparsely populated
                        areas
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                  <img
                    className="w-100"
                    src="/assets/img/directorates_objectives.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="director_program mar_100 container">
              <div className="acc_list">
                <div className="title_section text-center">
                  <h2>Program</h2>
                </div>
                <div
                  className="accordion mt-5"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingOne"
                    >
                      {" "}
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Welfare Schemes
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body ac-bo">
                        <ol>
                          <li>
                            Noon Meal is being provided for all the children of
                            Government and Aided Schools (Std 1-8).
                          </li>
                          <li>
                            4 Sets of Cost free Uniforms every year for all the
                            children who are enrolled in Noon Meal programme
                            (Std 1-8).
                          </li>
                          <li>
                            Cost Free Text Books to all children in Government
                            and Aided Schools and for Self Finance sections only
                            Tamil medium Text Books are being provided (Std
                            1-8).
                          </li>
                          <li>
                            Cost Free Note Books to all children of Government
                            and Aided Schools (Std 1-8).
                          </li>
                          <li>
                            Cost Free School Bag to all children of Government
                            and Aided Schools (Std 1-8).
                          </li>
                          <li>
                            Cost Free Crayons to 1 and 2nd Std children of
                            Government and Aided Schools.
                          </li>
                          <li>
                            Cost Free Colour Pencils to 3 to 5th Std children of
                            Government and Aided Schools.
                          </li>
                          <li>
                            Cost Free Geometry Boxes to 6th Std and newly
                            admitted children of 7th Std and 8th Std from Self
                            finance section / Self Finance Schools in Government
                            and Aided Schools.
                          </li>
                          <li>
                            Cost Free Atlas to 6th Std and newly admitted
                            children of 7th Std and 8th Std from Self finance
                            section / Self Finance Schools in Government and
                            Aided Schools.
                          </li>
                          <li>
                            Cost Free Chapels to 1 to 5th Std children of
                            Government and Aided Schools.
                          </li>
                          <li>
                            Cost Free Shoes and socks 6 to 8th Std children of
                            Government and Aided Schools.
                          </li>
                          <li>
                            Cost Free Woolen Sweater for all students in Hill
                            areas who are enrolled in Noon Meal programme ( Std
                            1-8 ).
                          </li>
                          <li>
                            Cost Free Boots and Socks for all students in Hill
                            areas who are enrolled in Noon Meal programme ( Std
                            1-8 ).
                          </li>
                          <li>
                            Cost Free Rain coat for all students in Hill areas
                            who are enrolled in Noon Meal programme ( Std 1-8 ).
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingTwo"
                    >
                      {" "}
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        {" "}
                        Ennum Ezhutthum Mission
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body ac-bo">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ea, exercitationem modi, quis beatae dignissimos
                          dolorem tempora nam voluptas ex fuga maiores eos
                          iusto. Deserunt dolore dolorem, tempore sit deleniti
                          ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingFour"
                    >
                      {" "}
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFour"
                      >
                        Providing quality education to all children
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFour"
                    >
                      <div className="accordion-body ac-bo">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ea, exercitationem modi, quis beatae dignissimos
                          dolorem tempora nam voluptas ex fuga maiores eos
                          iusto. Deserunt dolore dolorem, tempore sit deleniti
                          ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingFive"
                    >
                      {" "}
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFive"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFive"
                      >
                        Financial Assistance Schemes
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingFive"
                    >
                      <div className="accordion-body ac-bo">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ea, exercitationem modi, quis beatae dignissimos
                          dolorem tempora nam voluptas ex fuga maiores eos
                          iusto. Deserunt dolore dolorem, tempore sit deleniti
                          ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="director_program mar_100 container">
              <div className="acc_list">
                <div className="title_section text-center">
                  <h2>Services Offered</h2>
                </div>
                <div
                  className="accordion mt-5"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingOne"
                    >
                      {" "}
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Approval for New Self Financing Nursery and Primary
                        Schools
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne"
                    >
                      <div className="accordion-body ac-bo">
                        <ol>
                          <li>
                            Scope: To provide easy access to school for every
                            child of the age 3+ to 9+ in a neighbourhood area
                            for elementary education Beneficiaries: Registered
                            Trust / Society
                          </li>
                          <li>
                            Whom to apply: Block Educational Officer concerned
                            For more information on the eligibility criteria,
                            documents required, and grievance redressal{" "}
                            <span>
                              <a href="" target="_blank">
                                Click here
                              </a>
                            </span>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item ac-it">
                    <h2
                      className="accordion-header ac-he"
                      id="panelsStayOpen-headingTwo"
                    >
                      {" "}
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        {" "}
                        RTE Admission Under 25% reservation
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body ac-bo">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Ea, exercitationem modi, quis beatae dignissimos
                          dolorem tempora nam voluptas ex fuga maiores eos
                          iusto. Deserunt dolore dolorem, tempore sit deleniti
                          ipsum.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- directorates section ends --> */}

        {/* <!-- footer section starts --> */}
        <section className="footer_section mt_30">
          <div className="container">
            <div className="d-flex-jc">
              <div className="col-lg-3 col-12 mt_30">
                <h5>Location</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62183.46689263154!2d80.25117700000001!3d13.069583!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52666d712d3bc3%3A0xe2371e93cacea566!2sSamagra%20Shiksha%20(prev.%20Sarva%20Shiksha%20Abhiyan)%2C%20DPI!5e0!3m2!1sen!2sin!4v1736265523043!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: "0" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 px-sm-3 px-0 mt_30">
                <h5>Main</h5>
                <p>
                  <a href="/">Home</a>
                </p>
                <p>
                  <a href="/about_us">About Us</a>
                </p>
                <p>
                  <a href="">Testimonial</a>
                </p>
                <p>
                  <a href="">Event & Awards</a>
                </p>
                <p>
                  <a href="">Contact Us</a>
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 px-sm-2 px-0 mt_30">
                <h5>Reach Us</h5>
                <p>
                  <i className="bx bx-buildings"></i> Samagra Shiksha, Tamil
                  Nadu, P.A.K Valagam, College Road, Nungambakkam, Chennai - 600
                  006.
                </p>
                <h6 className="d-flex align-items-center">
                  <i className="bx bx-phone"></i>
                  <a href="tel:+91 98765 43210">+91 98765 43210</a>
                </h6>
                <h6 className="d-flex align-items-center">
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:dpeoftamilnadu@gmail.com">
                    dpeoftamilnadu@gmail.com
                  </a>
                </h6>
              </div>
              <div className="col-lg-3 col-12 text-center mt_30">
                <h5>Follow us</h5>
                <div className="soc_ic">
                  <a href="">
                    {" "}
                    <i className="fa-brands fa-facebook-f txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href="">
                    {" "}
                    <i className="fa-brands fa-instagram txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href="">
                    <i class="fa-brands fa-youtube txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href="">
                    <i className="fa-brands fa-twitter txt_wh"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default SchoolEducation;
