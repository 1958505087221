export const BASE_URL = "https://cmsapi-stag.tnsed.com/frontend/"; // LIVE URL
// export const BASE_URL = "http://127.0.0.1:8000/frontend/"; // local URL

export const URL = {
  // LOGIN
  LOGIN: "admin/login",
  LOGOUT: "admin-logout",
  ZONE: "zone",
  SCERT_CHILD_DATA:'scert-masters',
  VIEW_BOOK_PATHS:"scert-books",
  HEADER_DATA:"header-datas",
  FOOTER_DATA:"footer-datas",
  NAVIGATION:'navigation',
  BLOG:'blogs',
  BLOG_VIEW:'blogs/view',
  TEMPLETE_DETAILS:"template-details"
};

export const STATIC_URL = {
  MAGAZINE_LIST:"https://d1wpyxz35bzzz4.cloudfront.net/magazine_list_prod_json.json",
  MAGAZINE_SEND_OTP:"https://emis3.tnschools.gov.in/api/cmanOtpSend",
  MAGAZINE_OTP_CHECK:"https://emis3.tnschools.gov.in/api/cmanOtpCheck",
  MAGAZINE_PUBLISH:"https://emis3.tnschools.gov.in/api/magazinePubReg",
  FMS_MOBILE_SEND_OTP:'https://emis3.tnschools.gov.in/api/FMSUserMobSendOtp',
  FMS_MOBILE_VERIFY_OTP:"https://emis3.tnschools.gov.in/api/FMSUserMobVerifyOtp",
  FMS_MOBILE_NEW_USER:'https://emis3.tnschools.gov.in/api/FMSUserDetCUD',
  FMS_MOBILE_MASTER:"https://emis3.tnschools.gov.in/api/FMSUserDet", // ?flag=1&lclbdy_type=&dist_id
  KNOW_YOUR_EMIS_OTP:"https://emis3.tnschools.gov.in/api/STudMobSendOtp",
  KNOW_YOUR_EMIS_VERIFY_OTP:"https://emis3.tnschools.gov.in/api/STudMobVerifiOtp",
  FMS_USER_LIST: "https://emis3.tnschools.gov.in/api/FMSSchlDetUserGet",
  FMS_DEL_USER: "https://emis3.tnschools.gov.in/api/FMSSchlDet",
  FMS_DEL_USER_CUD:'https://emis3.tnschools.gov.in/api/FMSSchlDetUserCUD',
  TN_SCHOOL_COUNT:'https://d1wpyxz35bzzz4.cloudfront.net/tnschools_count.json'
}
export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";
export const ERROR_STATUS = "error_status";
// export const MAPS_API_KEY = "AIzaSyAiQyPFcrisIDlCqjPxWdnhxXPu1Ig1FHY";
export const MAPS_API_KEY = "AIzaSyC16FUHDJkJ7aCwPH1RNfN3tjtpCqObiq4";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
