import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fmsMobileVerifyOTP } from "../constants/Services/Api/api";
import toast from "react-hot-toast";
import Footer from "../Layout/Footer";

const FMSOTP = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Thirukural_img, setThirukural_img] = useState("");
  const [btn, setBtn] = useState(false);
  const [otp, setOtp] = useState("");

  const handleVerifyOTP = () => {
    if (otp?.length < 6) {
      toast.error("Please Enter OTP");
    } else {
      let payload = {
        records: {
          flag: "2",
          mob: location?.state?.number,
          otp: otp,
        },
      };
      setBtn(true);
      fmsMobileVerifyOTP(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            navigate('/FMS_application', {state:{userId:res?.user}})
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>FMS Program</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* FMS Program option starts */}

        <section className="fms_section mar_100">
          <div className="container">
            <div className="fms_card fms_login text-center w_500 flex-col">
              <h4>Enter Verification </h4>
              <p>
                We sent a code to xxxxxx{location?.state?.number?.slice(-4)}
              </p>
              <div>
                <input
                  type="number"
                  className="form-control"
                  name="otp"
                  placeholder=""
                  id="phone"
                  required
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 6) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 6) {
                      e.target.setCustomValidity("");

                      setOtp(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxLength={6}
                />
              </div>
              <div className="px-2">
                <button
                  onClick={() => handleVerifyOTP()}
                  disabled={btn}
                  className="a_blue"
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default FMSOTP;
