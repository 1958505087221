import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fmsMobileSendOTP } from "../constants/Services/Api/api";
import Footer from "../Layout/Footer";

const FMSLogin = () => {
  const navigate = useNavigate()
  const [btn, setBtn]=useState(false)
  const [number, setNumber]=useState("")
  const [Thirukural_img,setThirukural_img]=useState('')

  const getOTP=()=>{
    let payload = {
      records:{
        mob:number,
        "flag":"2"
      }
    }
    if(number?.length < 10){
      toast.error('Please Enter Mobile Number')
    }else{
      setBtn(true)
    fmsMobileSendOTP(payload).then((res)=>{
      setBtn(false)
      if(res?.dataStatus){
      toast.success(res?.message)
      navigate('/FMS_otp', {state:{number:number}})
    }else{
      toast.error(res?.message)
    }
    }).catch((err)=>{
      setBtn(false)
      console.log('err', err);
    })
  }
  }


  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>FMS Program</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* FMS Program option starts */}

        <section className="fms_section mar_100">
          <div className="container">
            <div className="fms_card fms_login text-center w_500 flex-col">
              <h2>FMS Login</h2>
              <form>
                <input
                  type="number"
                  className="form-control"
                  name="mobile"
                  placeholder="+91 "
                  id="phone"
                  maxlength="10"
                  oninput="this.value=this.value.slice(0,10)"
                  required
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Number");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");
    
                      setNumber(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  onChange={(e)=>setNumber(e.target.value)}
                  value={number}
                />
              </form>
              <div className="">
                <h6>
                  Don't have an Registered Mobile Number? <span> <a href="/#/FMS_newuser">Sign up</a></span>
                </h6>
              </div>
              <div className="px-2">
                <button onClick={()=>getOTP()}  className="a_blue" disabled={btn}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img}/>
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default FMSLogin;
