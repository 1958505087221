import { post, get, deletes, post_url, get_url } from "./method";
import { STATIC_URL, URL } from "../constants";

// LOGIN

export const login = async (data) => await post(URL.LOGIN, data);

export const logout = async () => await get(URL.LOGOUT);

export const deleteZone = async (id) => await deletes(URL.ZONE + "/" + id);


// SCERT
export const scertChildData = async (data) => await post(URL.SCERT_CHILD_DATA, data);
export const viewBookPaths = async (data) => await post(URL.VIEW_BOOK_PATHS, data);

// HEADER AND FOOTER
export const headerData = async (data) => await post(URL.HEADER_DATA, data);
export const footerData = async (data) => await post(URL.FOOTER_DATA, data);

// NAVIGATION
export const navigationData = async (data) => await post(URL.NAVIGATION, data);
export const templeteDetailsData = async (data) => await post(URL.TEMPLETE_DETAILS, data);

// BLOG
export const blogLIstData = async () => await post(URL.BLOG);
export const blogDetails = async (data) => await post(URL.BLOG_VIEW, data);


// NEW APIS
export const magazineGet = async ()=> await get_url(STATIC_URL.MAGAZINE_LIST)
export const fmsMobileMaster = async (params)=> await get_url(STATIC_URL.FMS_MOBILE_MASTER + params)
export const fmsMobileSendOTP = async (data)=> await post_url(STATIC_URL.FMS_MOBILE_SEND_OTP, data)
export const fmsMobileVerifyOTP = async (data)=> await post_url(STATIC_URL.FMS_MOBILE_VERIFY_OTP, data)
export const fmsMobileNewUser = async (data)=> await post_url(STATIC_URL.FMS_MOBILE_NEW_USER, data)
export const magazineSendOTP = async (data)=> await post_url(STATIC_URL.MAGAZINE_SEND_OTP, data)
export const magazineOTPVerify = async (data)=> await post_url(STATIC_URL.MAGAZINE_OTP_CHECK, data)
export const magazinePublish = async (data)=> await post_url(STATIC_URL.MAGAZINE_PUBLISH, data) 
export const fmsUserAgainst = async (id)=> await get_url(`${STATIC_URL.FMS_USER_LIST}?user=${id}`) 
export const fmsUserDelAdd = async (id,udise)=> await get_url(`${STATIC_URL.FMS_DEL_USER}?udise=${udise}&user_id=${id}`) 


export const fmsUserDelCUD = async (data)=> await post_url(STATIC_URL.FMS_DEL_USER_CUD, data)
export const knowEmisOTP = async (data)=> await post_url(STATIC_URL.KNOW_YOUR_EMIS_OTP, data)
export const knowEmisVerifyOTP = async (data)=> await post_url(STATIC_URL.KNOW_YOUR_EMIS_VERIFY_OTP, data)

export const tnSchoolCount = async ()=> await get_url(STATIC_URL.TN_SCHOOL_COUNT)
