import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  headerData,
  navigationData,
  templeteDetailsData,
} from "../constants/Services/Api/api";

const TopBar = ({ setNavigationDataList }) => {
  const languages = localStorage.getItem("language");
  const navigate = useNavigate();
  const translater = () => {
    if (languages == 1) {
      localStorage.setItem("language", 2);
    } else if (languages == 2) {
      localStorage.setItem("language", 1);
    }

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const [list, setList] = useState({});

  useEffect(() => {
    if (languages) {
      localStorage.setItem("language", languages);
    } else {
      localStorage.setItem("language", 1);
    }
  }, []);

  const getHeaders = () => {
    headerData()
      .then((res) => {
        let obj = { ...res };
        if (languages == 2) {
          obj.HEADER1_TITLE = res?.HEADER1_TAMIL_TITLE;
          obj.HEADER2_TITLE = res?.HEADER2_TAMIL_TITLE;
          obj.HEADER3_TITLE = res?.HEADER3_TAMIL_TITLE;
        } else {
          obj.HEADER1_TITLE = res?.HEADER1_ENG_TITLE;
          obj.HEADER2_TITLE = res?.HEADER2_ENG_TITLE;
          obj.HEADER3_TITLE = res?.HEADER3_ENG_TITLE;
        }
        // console.log('obj', obj);
        setList(obj);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [navigationList, setNavigationList] = useState([]);

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          let child_navigations = [];
          let obj = { ...item };
          if (languages == 2) {
            obj.name = item?.tamil_name;
          } else {
            obj.name = item?.eng_name;
          }

          item?.child_navigations?.map((chid, inx) => {
            let child_obj = { ...chid };
            if (languages == 2) {
              child_obj.name = chid?.tamil_name;
            } else {
              child_obj.name = chid?.eng_name;
            }
            child_navigations.push(child_obj);
          });

          obj.child_navigations = child_navigations;

          temp.push(obj);
        });
        setNavigationList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getHeaders();
    getNavigation();
    //  getTemplate("home")
  }, []);

  // console.log('navigationDataList', navigationDataList);

  return (
    <>
      {" "}
      <div className="nav_bar">
        <div className="top_section w-90">
          <div className="d-flex justify-content-lg-between justify-content-center align-items-center">
            <a href="/#/">
              <div className="logo_img">
                <img src="/assets/img/logo.png" alt="" />
              </div>
            </a>
            <div className="d-flex justify-content-between gap-3 align-items-center">
              <div className="lead_img">
                <div className="d-flex">
                  <div className="lead_card px-1">
                    <div className="lead_ic">
                      <img
                        src={
                          list?.HEADER1_IMAGE
                            ? list?.HEADER1_IMAGE
                            : "/assets/img/Hon_CM_Photo_cp.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <h6>{list?.HEADER1_TITLE}</h6>
                  </div>
                  <div className="lead_card px-1">
                    <div className="lead_ic">
                      <img
                        src={
                          list?.HEADER2_IMAGE
                            ? list?.HEADER2_IMAGE
                            : "/assets/img/Hon_Depty_CM_Photo_cp.jpg"
                        }
                        alt=""
                      />
                    </div>
                    <h6>{list?.HEADER2_TITLE}</h6>
                  </div>
                  <div className="lead_card px-1">
                    <div className="lead_ic">
                      <img
                        src={
                          list?.HEADER3_IMAGE
                            ? list?.HEADER3_IMAGE
                            : "/assets/img/edu_min_cp.jpeg"
                        }
                        alt=""
                      />
                    </div>
                    <h6>{list?.HEADER3_TITLE}</h6>
                  </div>
                </div>
              </div>
              <div>

                <button
                  className="translate_btn p-2 py-1"
                  onClick={() => translater()}
                >
                  {localStorage.getItem("language") == 2 ? "A" : "அ"}
                </button>



                {/* <BootstrapSwitchButton onClick={()=>translater()} checked={localStorage.getItem("language") == 2 ? true : false} width={100} onlabel={'A'} offlabel={"அ"} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-end">
          <Link className="old_site" to={"https://tnschools.gov.in/welcome"} target="_blank"> Switch Old Site</Link>
        </div>
        <div className="bg_gra">
          <nav className="navbar navbar-expand-lg navbar-light nav_me w-90">
            <Link className="" href="/">
              <img src="/assets/img/logo.png" alt="logo" />{" "}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa-solid fa-bars tx_blk"></i>
            </button>
            <div
              className="collapse navbar-collapse justify-content-lg-center jusitfy-content-end min-nav"
              id="collapsibleNavbar"
            >
              <ul className="navbar-nav nav_a">
                {navigationList?.map((item, ind) => {
                  return item?.child_navigations?.length > 0 ? (
                    <>
                      <li className="nav-item dropdown" key={ind}>
                        <a
                          className="nav-link dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          {item?.name}
                        </a>
                        <ul className="dropdown-menu dr_li">
                          {item?.child_navigations?.map((child, inx) => {
                            return child?.nav_type == 1 ? (
                              <Link
                                to={child?.redirect_url}
                                target={"_blank"}
                                style={{ color: "#212529" }}
                                className="dropdown-item dr_hv text-truncate"
                                key={inx}
                              >
                                {child?.name}
                              </Link>
                            ) : child?.nav_type == 4 ?   (
                              <Link
                                to={child?.redirect_url}
                                // target={"_blank"}
                                style={{ color: "#212529" }}
                                className="dropdown-item dr_hv text-truncate"
                                key={inx}
                              >
                                {child?.name}
                              </Link>
                            ) : (
                              <li
                                className="dropdown-item dr_hv text-truncate"
                                key={inx}
                                onClick={() => {
                                  if (child?.slug?.toLowerCase() == "scert") {
                                    navigate("/scert", {
                                      state: { data: child },
                                    });
                                  } else if (
                                    child?.slug?.toLowerCase() ==
                                    "know-your-emis"
                                  ) {
                                    navigate("/know-your-emis", {
                                      state: { data: child },
                                    });
                                  } else if (
                                    child?.slug?.toLowerCase() == "fms"
                                  ) {
                                    navigate("/FMS_program", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 1) {
                                    navigate("/", { state: { data: child } });
                                  } else if (child?.template_type == 2) {
                                    navigate("/about_us", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 3) {
                                    navigate("/directorates", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 4) {
                                    navigate("/magazine", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 5) {
                                    navigate("/ennum_ezhuthum", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 6) {
                                    navigate("/right_to_education", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 7) {
                                    navigate("/act_rules_codes", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 8) {
                                    navigate("/gos", {
                                      state: { data: child },
                                    });
                                  } else if (child?.template_type == 9) {
                                    navigate("/press_release", {
                                      state: { data: child },
                                    });
                                  }
                                }}
                              >
                                {child?.name}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>

                      {item?.nav_type == 1 ? (
                        <li className="nav-item" key={ind}>
                          <Link
                            to={item?.redirect_url}
                            target={"_blank"}
                            className="nav-link text-nowrap"
                          >
                            {item?.name}
                          </Link>
                        </li>
                      ) : item?.nav_type == 4 ? (
                        <li className="nav-item" key={ind}>
                          <Link
                            to={item?.redirect_url}
                            // target={"_blank"}
                            className="nav-link text-nowrap"
                          >
                            {item?.name}
                          </Link>
                        </li>
                      ) : (
                        <li className="nav-item" key={ind}>
                          <button
                            className="nav-link text-nowrap"
                            onClick={() => {
                              if (item?.slug?.toLowerCase() == "scert") {
                                navigate("/scert", { state: { data: item } });
                              } else if (
                                item?.slug?.toLowerCase() == "know-your-emis"
                              ) {
                                navigate("/know-your-emis", {
                                  state: { data: item },
                                });
                              } else if (item?.slug?.toLowerCase() == "fms") {
                                navigate("/FMS_program", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 1) {
                                navigate("/", { state: { data: item } });
                              } else if (item?.template_type == 2) {
                                navigate("/about_us", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 3) {
                                navigate("/directorates", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 4) {
                                navigate("/magazine", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 5) {
                                navigate("/ennum_ezhuthum", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 6) {
                                navigate("/right_to_education", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 7) {
                                navigate("/act_rules_codes", {
                                  state: { data: item },
                                });
                              } else if (item?.template_type == 8) {
                                navigate("/gos", { state: { data: item } });
                              } else if (item?.template_type == 9) {
                                navigate("/press_release", {
                                  state: { data: item },
                                });
                              }
                            }}
                          >
                            {item?.name}
                          </button>
                        </li>
                      )}
                    </>
                  );
                })}
                     <li className="nav-item">
                          <Link
                            to={'/blog'}
                            className="nav-link text-nowrap"
                          >
                            {'Blog'}
                          </Link>
                    </li>
                {/* <li className="nav-item ">
                  <Link className="nav-link" to="/about_us">
                    About Us
                  </Link>
                </li>
                <DropdownButton
                  id="dropdown-item-button"
                  variant="transparent"
                  title="Directorates"
                  data-bs-theme="light"
                >
                  <Link to={"/directorates"} className={"text-black"}>
                    <Dropdown.Item as="button">Directorates</Dropdown.Item>
                  </Link>
                  <Link to={"/school_education"} className={"text-black"}>
                    <Dropdown.Item as="button">School Education</Dropdown.Item>
                  </Link>
                  <Link to={"/matriculation_schools"} className={"text-black"}>
                    <Dropdown.Item as="button">
                      Matriculation Schools
                    </Dropdown.Item>
                  </Link>
                  <Link to={"/samaka_shiksha"} className={"text-black"}>
                    <Dropdown.Item as="button">Samaga Shiksha</Dropdown.Item>
                  </Link>
                  <Link
                    to={"/government_examinations"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">
                      Government Examinations
                    </Dropdown.Item>
                  </Link>
                  <Link to={"/non_formal_adult"} className={"text-black"}>
                    <Dropdown.Item as="button">
                      Non Formal and Adult Education
                    </Dropdown.Item>
                  </Link>

                  <Link to={"/scert"} className={"text-black"}>
                    <Dropdown.Item as="button">SCERT</Dropdown.Item>
                  </Link>
                </DropdownButton>

                <DropdownButton
                  id="dropdown-item-button"
                  variant="transparent"
                  title="Special Initiatives"
                  data-bs-theme="light"
                >
                  <Link
                    // to={"/directorates"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">Nan Mudhalvan</Dropdown.Item>
                  </Link>
                  <Link to={"/ennum_ezhuthum"} className={"text-black"}>
                    <Dropdown.Item as="button">Ennum Ezhuthum</Dropdown.Item>
                  </Link>
                  <Link
                    // to={"/directorates"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">Illam Thedi Kalvi</Dropdown.Item>
                  </Link>
                  <Link to={"/magazine"} className={"text-black"}>
                    <Dropdown.Item as="button">Magazine</Dropdown.Item>
                  </Link>
                </DropdownButton>

                <DropdownButton
                  id="dropdown-item-button"
                  variant="transparent"
                  title="Act & Gos"
                  data-bs-theme="light"
                >
                  <Link to={"/right_to_education"} className={"text-black"}>
                    <Dropdown.Item as="button">
                      Right to Education Act
                    </Dropdown.Item>
                  </Link>
                  <Link to={"/act_rules_codes"} className={"text-black"}>
                    <Dropdown.Item as="button">
                      Act, Rules & Codes
                    </Dropdown.Item>
                  </Link>
                  <Link to={"/gos"} className={"text-black"}>
                    <Dropdown.Item as="button">Gos</Dropdown.Item>
                  </Link>
                </DropdownButton>

                <DropdownButton
                  id="dropdown-item-button"
                  variant="transparent"
                  title="Right to Education"
                  data-bs-theme="light"
                >
                  <Link
                    //  to={"/grievance_redressal"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">
                      Grievance redressal
                    </Dropdown.Item>
                  </Link>
                  <Link
                    //  to={"/public_information_officers"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">
                      Public information officers
                    </Dropdown.Item>
                  </Link>
                </DropdownButton>

                <DropdownButton
                  id="dropdown-item-button"
                  variant="transparent"
                  title="Press Release"
                  data-bs-theme="light"
                >
                  <Link to={"/press_release"} className={"text-black"}>
                    <Dropdown.Item as="button">Press Release</Dropdown.Item>
                  </Link>
                  <Link
                    //  to={"/tenders"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">Tenders</Dropdown.Item>
                  </Link>
                  <Link
                    // to={"/media_registration_form"}
                    className={"text-black"}
                  >
                    <Dropdown.Item as="button">
                      Media Registration Form
                    </Dropdown.Item>
                  </Link>
                </DropdownButton>
                <li className="nav-item ">
                  <Link className="nav-link" to="/know_your_emis">
                    Know your EMIS
                  </Link>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default TopBar;
