import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fmsMobileVerifyOTP } from "../constants/Services/Api/api";
import toast from "react-hot-toast";
import Footer from "../Layout/Footer";
import CustomTable from "../Layout/CustomTable";

const KnowYourEmisList = () => {
  const languages = localStorage.getItem("language");
  const navigate = useNavigate();
  const location = useLocation();
  const [Thirukural_img, setThirukural_img] = useState("");
  const [btn, setBtn] = useState(false);

  const [list, setList] = useState([]);

  const result = [
    {
      user_id: "1000001099",
      name: "AJITH KUMAR V",
      name_tamil:
        "\u0b85\u0b9c\u0bbf\u0ba4\u0bcd \u0b95\u0bc1\u0bae\u0bbe\u0bb0\u0bcd \u0bb5\u0bbf",
      mother_name: "JAYAMARY V",
      mother_name_tamil:
        "\u0b9c\u0bc6\u0baf\u0bae\u0bc7\u0bb0\u0bbf \u0bb5\u0bbf",
      father_name: "VICTOR AROCKIARAJ K",
      father_name_tamil:
        "\u0bb5\u0bbf\u0b95\u0bcd\u0b9f\u0bb0\u0bcd \u0b86\u0bb0\u0bcb\u0b95\u0bcd\u0b95\u0bbf\u0baf\u0bb0\u0bbe\u0b9c\u0bcd  \u0b95\u0bc1",
      guardian_name: "",
      guardian_name_tamil: "",
      dob: "2006-12-21",
      class_studying_id: "12",
      class_section: "F",
      class_studying: "XII",
      udise_code: "33150900805",
      school_name: "E.R.HR.SEC.SCHOOL",
      mob_verf_flag: "0",
      mob_verf_meb: null,
      phone_number: "XXXXXX1288",
    },
  ];

  const getList = () => {
    if (Array.isArray(location?.state?.data)) {
      let temp = [...location?.state?.data];
      // let temp = [...result];
      let arr = [];

      temp?.map((item, ind) => {
        let obj = { ...item };

        if (languages == 2) {
          obj.name_ = item?.name_tamil;
          obj.mother_name_ = item?.mother_name_tamil;
          obj.father_name_ = item?.father_name_tamil;
          obj.guardian_name_ = item?.guardian_name_tamil;
        } else {
          obj.name_ = item?.name;
          obj.mother_name_ = item?.mother_name;
          obj.father_name_ = item?.father_name;
          obj.guardian_name_ = item?.guardian_name;
        }

        arr.push(obj);
      });

      setList(arr);
    }
  };

  useEffect(() => {
    if (location?.state?.data?.length > 0) {
      getList();
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "EMIS ID",
        accessor: "user_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Student Name",
        accessor: "name_",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Class & Section",
        accessor: "desc3",
        Cell: ({ value, row }) => {
          return (
            <>
              {row.original.class_studying}
              {"-"}
              {row.original.class_section}
            </>
          );
        },
      },
      {
        Header: "School",
        accessor: "school_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "DOB",
        accessor: "dob",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Father Name",
        accessor: "father_name_",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Mother Name",
        accessor: "mother_name_",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Guardian Name",
        accessor: "guardian_name_",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Status",
        accessor: "mob_verf_flag",
        Cell: ({ value, row }) => {
          return (
            <p className={+value == 1 ? "text-success" : "text-danger"}>
              {+value == 1 ? "Verified" : "Not Verified"}
            </p>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="main">
        {/* FMS Program option starts */}

        <section className="fms_section mar_100">
          <div className="container">
            <div className="title_section_emis text-center">
              <h4>KNOW YOUR EMIS (Search by Mobile Number)</h4>
            </div>
            <div className="d-flex justify-content-end">
              <button
                style={{
                  padding: ".375375rem .875rem",
                  fontSize: ".875rem",
                  color: "#333",
                  background: "#ffba01",
                  border: "1px solid #ffba01",
                }}
                onClick={()=>navigate('/know-your-emis')}
              >
                Back
              </button>
            </div>
            <div className="table_section ">
              <CustomTable columns={columns} data={list} searchBox={"hide"} />
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default KnowYourEmisList;
