import React, { useEffect, useState } from "react";
import { footerData } from "../constants/Services/Api/api";

const Footer = ({ setThirukural_img, setThirukural}) => {
  const languages = localStorage.getItem("language");
  const [list, setList] = useState({});

  const getHeaders = () => {
    footerData()
      .then((res) => {
        setList(res);
        if (res?.THIRUKURAL_IMAGE) {
          setThirukural_img(res?.THIRUKURAL_IMAGE);
          setThirukural(res)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getHeaders();
  }, []);

  return (
    <>
      <section className="">
        <div className="footer_section">
          <div className="container">
            <div className="d-flex-jc">
              <div className="col-lg-3 col-12 mt_30">
                <h5>Location</h5>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62183.46689263154!2d80.25117700000001!3d13.069583!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52666d712d3bc3%3A0xe2371e93cacea566!2sSamagra%20Shiksha%20(prev.%20Sarva%20Shiksha%20Abhiyan)%2C%20DPI!5e0!3m2!1sen!2sin!4v1736265523043!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: "0" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 px-sm-3 px-0 mt_30">
                <h5>Main</h5>
                <p>
                  <a href="/">Home</a>
                </p>
                <p>
                  <a href="/#/about_us">About Us</a>
                </p>
                <p>
                  <a href="/#/scert">Scert</a>
                </p>
                <p>
                  <a href="/#/press_release">Press Release</a>
                </p>
                <p>
                  <a href="/#/know-your-emis">Know your emis</a>
                </p>
                <p>
                  <a href="/#/blog">Blog</a>
                </p>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 px-sm-2 px-0 mt_30">
                <h5>Reach Us</h5>
                <p>
                  <i className="bx bx-buildings"></i> {list?.REACH_US_ADDRESS}
                </p>
                <h6 className="d-flex align-items-center">
                  <i className="bx bx-phone"></i>
                  <a href={`tel:${list?.REACH_US_PHONE}`}>
                    {list?.REACH_US_PHONE}
                  </a>
                </h6>
                <h6 className="d-flex align-items-center">
                  <i className="bx bx-envelope"></i>
                  <a href={`mailto:${list?.REACH_US_EMAIL}`}>
                    {list?.REACH_US_EMAIL}
                  </a>
                </h6>
              </div>
              <div className="col-lg-3 col-12 text-center mt_30">
                <h5>Follow us</h5>
                <div className="soc_ic">
                  <a href={list?.FACEBOOK_LINK} target={"_blank"}>
                    {" "}
                    <i className="fa-brands fa-facebook-f txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href={list?.INSTA_LINK} target={"_blank"}>
                    {" "}
                    <i className="fa-brands fa-instagram txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href={list?.LINKEDIN_LINK} target={"_blank"}>
                    <i class="fa-brands fa-youtube txt_wh"></i>
                  </a>
                </div>
                <div className="soc_ic">
                  <a href={list?.TWITTER_LINK} target={"_blank"}>
                    <i className="fa-brands fa-twitter txt_wh"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bott_c flex-column text-center">
          <small className="text-light mb-3 mt-3">
            Content of this website is designed, developed, hosted, owned and
            managed by Department of School Education, Tamil Nadu.
          </small>
          <small className="text-light">@ 2025 Copyright :TNSED</small>
        </div>
        <div className="d-flex text-center justify-content-center p-3">
          <small className="text-dark">
            Copyright All Rights Reserved. Department of School Education
          </small>
        </div>
      </section>
      
      <div className="quick_circle">
        <div class="menu_cr">
          <input type="checkbox" id="toggle" className="d-none" />
          <label id="show-menu" for="toggle">
            <div class="cir_btn">
              <i class="fa-solid fa-bars toggleBtn menuBtn">menu</i>
              <i class="fa-solid fa-xmark toggleBtn closeBtn">close</i>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
            <div class="menu_at">
              <a href="">
                <img src="/assets/img/dedicted_officiers.png" alt="" />
              </a>
            </div>
          </label>
        </div>
      </div>
      
    </>
  );
};

export default Footer;
