import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import TopBar from "../Layout/TopBar";
import Accordion from "react-bootstrap/Accordion";
import {
  navigationData,
  templeteDetailsData,
} from "../constants/Services/Api/api";

const AboutUs = () => {
  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [thirukural_img, setThirukural_img] = useState("");
  const [thirukural, setThirukural] = useState("");
  // console.log('navigationDataList', navigationDataList);

  const getTemplate = (navigation_slug, type) => {
    let payload = new FormData();
    payload.append("navigation_slug", navigation_slug);
    templeteDetailsData(payload)
      .then((res) => {
        let data = res?.response;
        let temp = { ...data };

        if (data?.banner_json) {
          let banner_json = { ...data?.banner_json };
          if (languages == 2) {
            banner_json.title = data?.banner_json?.tamil_title;
            banner_json.sub_title = data?.banner_json?.tamil_tsub_title;
          } else {
            banner_json.title = data?.banner_json?.eng_title;
            banner_json.sub_title = data?.banner_json?.eng_sub_title;
          }
          temp.banner_json = banner_json;
        }

        // TAB 2

        if (data?.history_json) {
          let history_json = { ...data?.history_json };
          if (languages == 2) {
            history_json.title = data?.history_json?.tamil_title;
            history_json.desc = data?.history_json?.tamil_desc;
          } else {
            history_json.title = data?.history_json?.eng_title;
            history_json.desc = data?.history_json?.eng_desc;
          }
          temp.history_json = history_json;
        }

        if (data?.mission_json) {
          let mission_json = { ...data?.mission_json };
          if (languages == 2) {
            mission_json.title = data?.mission_json?.tamil_title;
            mission_json.desc = data?.mission_json?.tamil_desc;
          } else {
            mission_json.title = data?.mission_json?.eng_title;
            mission_json.desc = data?.mission_json?.eng_desc;
          }
          temp.mission_json = mission_json;
        }

        if (data?.policy_json) {
          let policy_json = { ...data?.policy_json};
          let  commitment_lists_ = data?.policy_json?.faqs ? Object.values(data?.policy_json?.faqs) : '';
          let commitment_lists = [];
          if(commitment_lists_?.length){
            commitment_lists_?.map((item, ind) => {
             let commitment_ = { ...item };
             if (languages == 2) {
               commitment_.title = item?.tamil_title;
               commitment_.description = item?.tamil_description;
             } else {
               commitment_.title = item?.eng_title;
               commitment_.description = item?.eng_description;
             }
             commitment_lists.push(commitment_);
           });
          }
          if (languages == 2) {
            policy_json.title = data?.policy_json?.tamil_title;
            policy_json.desc = data?.policy_json?.tamil_desc;
          } else {
            policy_json.title = data?.policy_json?.eng_title;
            policy_json.desc = data?.policy_json?.eng_desc;
          }
          temp.policy_json = policy_json;
          temp.policy_json['faqs'] = commitment_lists;

        }

        setNavigationDataList(temp);

        // console.log('dddddddddddddd', temp);
        
        // to={item?.template_type == 2 ? "/about_us"
        //   :'/'
        //  }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 2);
        if (finder?.id) {
          getTemplate(finder?.slug);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.slug) {
      getTemplate(location?.state?.data?.slug);
    } else {
      getNavigation();
    }
  }, []);

  return (
    <>
      <div className="main">
        {/* <TopBar setNavigationDataList={setNavigationDataList}/> */}
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>{navigationDataList?.banner_json?.title}</h1>
              <p>{navigationDataList?.banner_json?.sub_title}</p>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- about page sections starts --> */}

        <div className="tn_about mar_100">
          <div className="container">
            <div className="d-flex-jc">
              <div className="col-lg-6 col-12 pe-lg-5 pe-0  mt_30">
                <div className="title_section">
                  <h2> {navigationDataList?.mission_json?.title}</h2>
                  <p
                    className="text_just"
                    dangerouslySetInnerHTML={{
                      __html: navigationDataList?.mission_json?.desc,
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt_30">
                <div className="pos_sti">
                  <div className="about_img">
                    <img
                      src={navigationDataList?.mission_json?.image_path}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className="about_img">
                  <img src={navigationDataList?.mission_json?.image2_path} alt="" />
                </div>
                <div className="about_img">
                  <img src={navigationDataList?.mission_json?.image3_path} alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- about page section ends --> */}

        {/* <!-- history section starts --> */}

        <section
          className="history_section mar_100"
          style={{
            backgroundImage: `url(${navigationDataList?.history_json?.background_path})`,
          }}
        >
          <div className="container">
            <div className="d-flex-jc pos_rel">
              <div className="col-lg-6 col-12 pe-lg-5 pe-0 mt_30">
                <div className="pos_sti">
                  <div className="about_img">
                    <img
                      src={navigationDataList?.history_json?.image_path}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className="about_img">
                  <img src={navigationDataList?.history_json?.image2_path} alt="" />
                </div>
                <div className="about_img">
                  <img src={navigationDataList?.history_json?.image3_path} alt="" />
                </div> */}
              </div>
              <div className="col-lg-6 col-12 mt_30">
                <div className="title_section">
                  <h2>{navigationDataList?.history_json?.title}</h2>
                  <p
                    className="text_just"
                    dangerouslySetInnerHTML={{
                      __html: navigationDataList?.history_json?.desc,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- history section ends --> */}

        {/* <!-- policy note starts --> */}

        <section className="policy_note mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>{navigationDataList?.policy_json?.title}</h2>
              <p
                className="text_just"
                dangerouslySetInnerHTML={{
                  __html: navigationDataList?.policy_json?.desc,
                }}
              ></p>
            </div>
          </div>

          <div className="director_program mar_100 container">
              <div className="acc_list">
                <div className="title_section text-center">
                  <h2>{'FAQ'}</h2>
                </div>
                <Accordion defaultActiveKey="0">
                  {navigationDataList?.policy_json?.faqs?.map(
                    (item, ind) => {
                      return (
                        <Accordion.Item eventKey={ind}>
                          <Accordion.Header className="accordion-item ac-it">
                            <h2 className="accordion-header ac-he">
                              {item?.title}
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-body ac-bo">
                              <ol style={{ listStyleType: "none" }}>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></li>
                              </ol>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    }
                  )}
                </Accordion>
              </div>
            </div>

        </section>

        {/* <!-- policy note ends --> */}

        {/* <!-- thirukural starts --> */}

        {/* <section className="thirukural_section ">
          <div className="thiru_img">
            <img
              src={
                thirukural_img
                  ? thirukural_img
                  : "/assets/img/thirukural_section.webp"
              }
              alt=""
            />
          </div>
          <div className="thiru_img d-lg-none d-block">
            <img src="/assets/img/thirukural_section_md.webp" alt="" />
          </div>
        </section> */}

<section className="th_section">
          <div className="container">
            <div className="th_flex">
              <div className="col-md-4">
                <div className="thi_img">
                  <img src="/assets/img/thiruvaluvar_ic.webp" alt="" />
                </div>
              </div>
              <div className="col-md-8 ps-md-3 ps-0">
                <div className="th_cont">
                  <span>{thirukural?.THIRUKURAL_TITLE}</span>
                  <h3 dangerouslySetInnerHTML={{__html:thirukural?.THIRUKURAL}}></h3>
                  <p>{thirukural?.THIRUKURAL_DESC}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- thirukural ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural={setThirukural} setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default AboutUs;
