import React from "react";
import CustomTable from "../Layout/CustomTable";

const TableColumns3 = ({ navigationDataList }) => {
  const column2 = React.useMemo(
    () => [
      {
        Header: navigationDataList?.header_json?.title1,
        accessor: "desc1",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: navigationDataList?.header_json?.title2,
        accessor: "desc2",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: navigationDataList?.header_json?.title3,
        accessor: "image_path",
        Cell: ({ value, row }) => {
          return (
            <div className="btn_blue_tab">
              <a href={value} target={"_blank"} style={{ cursor: "pointer" }}>
                Download <i className="ri-download-2-line"></i>
              </a>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <CustomTable columns={column2} data={navigationDataList?.act_code_json} />
  );
};

export default TableColumns3;
