import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import {
  fmsMobileVerifyOTP,
  fmsUserAgainst,
  fmsUserDelAdd,
  fmsUserDelCUD,
} from "../constants/Services/Api/api";
import toast from "react-hot-toast";
import Footer from "../Layout/Footer";
import CustomTable from "../Layout/CustomTable";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function FmsDrlModal(props) {
  const { state } = useLocation();
  const [formData, setFormdata] = useState({
    udise_code: props?.data?.udise_code ?? "",
    school_id: props?.data?.school_id ?? "",
    school_name: props?.data?.school_name ?? "",
    zone_name: props?.data?.zone_name ?? "",
    total_boys: props?.data?.total_boys ?? "",
    total_girs: props?.data?.total_girs ?? "",
    total_trans: props?.data?.total_trans ?? "",
    total_students: props?.data?.total_students ?? "",
    builtup_area_exclu_toilet_sqm:
      props?.data?.builtup_area_exclu_toilet_sqm ?? "",
    toilet_area_sqm: props?.data?.toilet_area_sqm ?? "",
    total_area_built_up_toilet_sqm:
      props?.data?.total_area_built_up_toilet_sqm ?? "",
    total_open_area_sq_mt: props?.data?.total_open_area_sq_mt ?? "",
    h_k_sanitation_staff: props?.data?.h_k_sanitation_staff ?? "",
    security_staff: props?.data?.security_staff ?? "",
    staff_for_open_area_nos: props?.data?.staff_for_open_area_nos ?? "",
    total_staff: props?.data?.total_staff ?? "",
    total_girls_toilet: props?.data?.total_girls_toilet ?? "",
    total_boys_toilet: props?.data?.total_boys_toilet ?? "",
    total_common_toilet: props?.data?.total_common_toilet ?? "",
    total_cwsn_boys_toilet: props?.data?.total_cwsn_boys_toilet ?? "",
    total_cwsn_girls_toilet: props?.data?.total_cwsn_girls_toilet ?? "",
    total_common_cwsn_toilet: props?.data?.total_common_cwsn_toilet ?? "",
    total_male_staff_toilet: props?.data?.total_male_staff_toilet ?? "",
    total_female_staff_toilet: props?.data?.total_female_staff_toilet ?? "",
    total_common_staff_toilet: props?.data?.total_common_staff_toilet ?? "",
    total_toilets_in_schools: props?.data?.total_toilets_in_schools ?? "",
    localbody_type: props?.data?.localbody_type ?? "",
    salary: props?.data?.salary ?? "",
    other_expenditure: props?.data?.other_expenditure ?? "",
    total: props?.data?.total ?? "",
    budget_feasibile: props?.data?.budget_feasibile ?? "",
    ifno_remarks: props?.data?.ifno_remarks ?? "",
    ceo_id: props?.data?.ceo_id ?? null,
    ceo_approval_sts: props?.data?.ceo_approval_sts ?? "",
    ceo_ifrejected_remarks: props?.data?.ceo_ifrejected_remarks ?? null,
    pdf_view: props?.data?.pdf_view ?? "",
    district_name: props?.data?.district_name ?? "",
    block_name: props?.data?.block_name ?? "",
    formSubmittedDate: props?.data?.formSubmittedDate ?? "",
    category: props?.data?.category ?? "",
    management: props?.data?.management ?? "",
    cate_type: props?.data?.cate_type ?? "",
    localbody_name: props?.data?.localbody_name ?? "",
    type: props?.data?.type ?? "",
    reason: props?.data?.reason ?? "",
    indicate: props?.data?.indicate ?? "",
  });

  useEffect(() => {
    if (props?.data) {
      const obj = props?.data;
      setFormdata({
        ...formData,
        ...obj,
        budget_feasibile: obj?.budget_feasibile ? obj?.budget_feasibile : "",
        ifno_remarks: obj?.ifno_remarks ? obj?.ifno_remarks : "",
        pdf_view: obj?.pdf_view ? obj?.pdf_view : "",
      });
    }
  }, [props?.data]);

  const handleChange = (e) => {
    const [name, value] = e.target;
    setFormdata({ ...formData, [name]: value });
  };

  const aTager = () => {
    setFormdata((val) => ({ ...val, ["pdf_view"]: "1" }));
    const link = document.createElement("a");
    link.href = "https://d1e5r329t7a85t.cloudfront.net/FMS_29_06_2024.pdf";
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
  };

  console.log("formData", formData);

  const submitHandler = () => {
    let payload = {
      records: {
        budget_feasibile: formData?.budget_feasibile,
        ceo_approval_sts: formData?.ceo_approval_sts,
        ifno_remarks: formData?.ifno_remarks,
        pdf_view: formData?.pdf_view,
        school_id: formData?.school_id,
        user_indx_id: state?.userId,
      },
    };

    if (props?.details?.id) {
      payload.records.id = props?.details?.id;
    }

    if (formData?.budget_feasibile == "") {
      toast.error("Please Select Indicate YES/No");
    } else if (
      formData?.budget_feasibile == "no" &&
      formData?.ifno_remarks == ""
    ) {
      toast.error("Please Add Reason");
    } else if (+formData?.pdf_view == "") {
      toast.error(`Please 'View Calculation Details' document at least once.`);
    } else {
      fmsUserDelCUD(payload)
        .then((res) => {
          if (res?.dataStatus) {
            toast.success(res?.message);
            props?.getList(state?.userId);
            props?.setDetails({});
            props?.onHide();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {formData?.school_name} ({formData.udise_code})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="fms-form p-2">
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">District</label>
              <input
                className="form-control"
                value={formData.district_name}
                name="district_name"
                onChange={handleChange}
                placeholder="Enter District"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Block</label>
              <input
                className="form-control"
                value={formData.block_name}
                name="block_name"
                onChange={handleChange}
                placeholder="Enter Block"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Name of Zone</label>
              <input
                className="form-control"
                value={formData.zone_name}
                name="zone_name"
                onChange={handleChange}
                placeholder="Enter Name of Zone"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Localbody Name</label>
              <input
                className="form-control"
                value={formData.localbody_name}
                name="localbody_name"
                onChange={handleChange}
                placeholder="Enter Localbody Name"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">School Type</label>
              <input
                className="form-control"
                value={formData.type}
                name="type"
                onChange={handleChange}
                placeholder="Enter School Type"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Category</label>
              <input
                className="form-control"
                value={formData.category}
                name="category"
                onChange={handleChange}
                placeholder="Enter Category"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Management</label>
              <input
                className="form-control"
                value={formData.management}
                name="management"
                onChange={handleChange}
                placeholder="Enter Management"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Boys</label>
              <input
                className="form-control"
                value={formData.total_boys}
                name="total_boys"
                onChange={handleChange}
                placeholder="Enter Total Boys"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Girls</label>
              <input
                className="form-control"
                value={formData.total_girs}
                name="total_girs"
                onChange={handleChange}
                placeholder="Enter Total Girls"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Transgender</label>
              <input
                className="form-control"
                value={formData.total_trans}
                name="total_trans"
                onChange={handleChange}
                placeholder="Enter Total Transgender"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Students</label>
              <input
                className="form-control"
                value={formData.total_students}
                name="total_students"
                onChange={handleChange}
                placeholder="Enter Total Students"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">
                Builtup Area (Excluding Toilets) in sqm
              </label>
              <input
                className="form-control"
                value={formData.builtup_area_exclu_toilet_sqm}
                name="builtup_area_exclu_toilet_sqm"
                onChange={handleChange}
                placeholder="Enter Builtup Area (Excluding Toilets) in sqm"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Toilet Area in sqm</label>
              <input
                className="form-control"
                value={formData.toilet_area_sqm}
                name="toilet_area_sqm"
                onChange={handleChange}
                placeholder="Enter Toilet Area in sqm"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">
                Total Area (Built up + Toilet) in sqm
              </label>
              <input
                className="form-control"
                value={formData.total_area_built_up_toilet_sqm}
                name="total_area_built_up_toilet_sqm"
                onChange={handleChange}
                placeholder="Enter Total Area (Built up + Toilet) in sqm"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total open area (sq. mt.)</label>
              <input
                className="form-control"
                value={formData.total_open_area_sq_mt}
                name="total_open_area_sq_mt"
                onChange={handleChange}
                placeholder="Enter Total open area (sq. mt.)"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">H/K & Sanitation Staff</label>
              <input
                className="form-control"
                value={formData.h_k_sanitation_staff}
                name="h_k_sanitation_staff"
                onChange={handleChange}
                placeholder="Enter H/K & Sanitation Staff"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Security Staff</label>
              <input
                className="form-control"
                value={formData.security_staff}
                name="security_staff"
                onChange={handleChange}
                placeholder="Enter Security Staff"
                type="text"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Staff for open area (Nos)</label>
              <input
                className="form-control"
                value={formData.staff_for_open_area_nos}
                name="staff_for_open_area_nos"
                onChange={handleChange}
                placeholder="Enter Staff for open area (Nos)"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Staff</label>
              <input
                className="form-control"
                value={formData.total_staff}
                name="total_staff"
                onChange={handleChange}
                placeholder="Enter Total Staff"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Girls Toilet</label>
              <input
                className="form-control"
                value={formData.total_girls_toilet}
                name="total_girls_toilet"
                onChange={handleChange}
                placeholder="Enter Total Girls Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Boys Toilet</label>
              <input
                className="form-control"
                value={formData.total_boys_toilet}
                name="total_boys_toilet"
                onChange={handleChange}
                placeholder="Enter Total Boys Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Common Toilet</label>
              <input
                className="form-control"
                value={formData.total_common_toilet}
                name="total_common_toilet"
                onChange={handleChange}
                placeholder="Enter Total Common Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total CWSN Boys Toilet</label>
              <input
                className="form-control"
                value={formData.total_cwsn_boys_toilet}
                name="total_cwsn_boys_toilet"
                onChange={handleChange}
                placeholder="Enter Total CWSN Boys Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total CWSN Girls Toilet</label>
              <input
                className="form-control"
                value={formData.total_cwsn_girls_toilet}
                name="total_cwsn_girls_toilet"
                onChange={handleChange}
                placeholder="Enter Total CWSN Girls Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total CWSN Common Toilet</label>
              <input
                className="form-control"
                value={formData.total_common_cwsn_toilet}
                name="total_common_cwsn_toilet"
                onChange={handleChange}
                placeholder="Enter Total CWSN Common Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Male Staff Toilet</label>
              <input
                className="form-control"
                value={formData.total_boys_toilet}
                name="total_boys_toilet"
                onChange={handleChange}
                placeholder="Enter Total Male Staff Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Female Staff Toilet</label>
              <input
                className="form-control"
                value={formData.total_female_staff_toilet}
                name="total_female_staff_toilet"
                onChange={handleChange}
                placeholder="Enter Total Female Staff Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Common Staff Toilet</label>
              <input
                className="form-control"
                value={formData.total_common_staff_toilet}
                name="total_common_staff_toilet"
                onChange={handleChange}
                placeholder="Enter Total Common Staff Toilet"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Toilets in Schools</label>
              <input
                className="form-control"
                value={formData.total_toilets_in_schools}
                name="total_toilets_in_schools"
                onChange={handleChange}
                placeholder="Enter Total Toilets in Schools"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Salary</label>
              <input
                className="form-control"
                value={formData.salary}
                name="salary"
                onChange={handleChange}
                placeholder="Enter Salary"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Other Expenditure</label>
              <input
                className="form-control"
                value={formData.other_expenditure}
                name="other_expenditure"
                onChange={handleChange}
                placeholder="Enter Other Expenditure"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">Total Expense</label>
              <input
                className="form-control"
                value={formData.total}
                name="total"
                onChange={handleChange}
                placeholder="Enter Total Expense"
                type="number"
                readOnly
              />
            </div>

            <div className="col-md-4 col-sm-6 mb-1">
              <label className="form-label">
                Indicate YES/No, Whether you have enough amount to meet the
                expenses in your local body?
              </label>
              <select
                className="form-select"
                value={formData.budget_feasibile}
                name="budget_feasibile"
                onChange={(e) =>
                  setFormdata({ ...formData, budget_feasibile: e.target.value })
                }
              >
                <option value={""}>Select Yes/No</option>
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </select>
            </div>

            {formData.budget_feasibile == "no" ? (
              <div className=" col-sm-6 mb-1">
                <label className="form-label">Reason</label>
                <textarea
                  className="form-control"
                  value={formData.ifno_remarks}
                  name="reason"
                  onChange={(e) => {
                    setFormdata((val) => ({
                      ...val,
                      ["ifno_remarks"]: e.target.value,
                    }));
                  }}
                  placeholder="Enter reason"
                  type="text"
                />
              </div>
            ) : (
              ""
            )}

            <div className="d-sm-flex justify-content-between mt-4">
              <div>
                <p onClick={() => aTager()} style={{ cursor: "pointer" }}>
                  view Calculation Details
                </p>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => submitHandler()}
                  disabled={
                    formData?.ceo_approval_sts == "approved"
                      ? true
                      : formData?.ceo_approval_sts == "rejected"
                      ? true
                      : false
                  }
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    props.onHide();
                    props?.setDetails({});
                  }}
                  className="btn btn-danger"
                >
                  cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
    </Modal>
  );
}

const FMSApplication = () => {
  const languages = localStorage.getItem("language");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [Thirukural_img, setThirukural_img] = useState("");
  const [btn, setBtn] = useState(false);
  const [udise, setUdise] = useState("");

  const [details, setDetails] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState({});

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const [list, setList] = useState([]);

  const result = [
    {
      id: "104",
      udise_code: "33050317102",
      school_id: "10364",
      school_name: "GHSS P.GOLLAPATTI",
      zone_name: "3 (West)",
      total_boys: "311",
      total_girs: "268",
      total_trans: "0",
      total_students: "579",
      builtup_area_exclu_toilet_sqm: "584.600000",
      toilet_area_sqm: "29.780000",
      total_area_built_up_toilet_sqm: "614.380000",
      total_open_area_sq_mt: "8049.788000",
      h_k_sanitation_staff: "1",
      security_staff: "2",
      staff_for_open_area_nos: "1",
      total_staff: "4",
      total_girls_toilet: "1",
      total_boys_toilet: "1",
      total_common_toilet: "0",
      total_cwsn_boys_toilet: "1",
      total_cwsn_girls_toilet: "1",
      total_common_cwsn_toilet: "0",
      total_male_staff_toilet: "0",
      total_female_staff_toilet: "0",
      total_common_staff_toilet: "0",
      total_toilets_in_schools: "4",
      localbody_type: "Village Panchayat",
      salary: "609960",
      other_expenditure: "52669.530000",
      total: "662629.530000000000",
      budget_feasibile: "yes",
      ifno_remarks: "",
      ceo_id: null,
      ceo_approval_sts: "pending",
      ceo_ifrejected_remarks: null,
      pdf_view: "1",
      district_name: "DHARMAPURI",
      block_name: "Pennagaram",
      formSubmittedDate: "2024-07-04",
      category: "Hr.Sec School (VI-XII)",
      management: "School Education Department School",
      cate_type: "Higher Secondary School",
      localbody_name: "P. GOLLAHALLI",
      type: "Co-Ed",
    },
  ];

  const getList = (id) => {
    fmsUserAgainst(id)
      .then((res) => {
        if (res?.dataStatus) {
          console.log("ress", res);
          setList(res?.result ?? []);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleAdd = () => {
    if(udise?.length == 0){
      toast.error("Please Enter UDISE");
    }else{
    fmsUserDelAdd(state?.userId, udise)
      .then((res) => {
        if (res?.dataStatus) {
          console.log("ress", res);
          setData(res?.result?.length > 0 ? res?.result[0] : []);
          setTimeout(() => {
            handleShow();
          }, 500);
          setUdise("")
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => console.log("err", err));
    }
  };

  useEffect(() => {
    if (state?.userId) {
      getList(state?.userId);
    }
  }, [state]);

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const [show, setShow] = useState(false);
  const [reasons, setReasons] = useState("");
  const getReason = (item) => {
    setReasons(item);
    setShow(true);
  };

  const handlUpdate = (item) => {
    setData(item);
    setDetails(item);
    setTimeout(() => {
      handleShow();
    }, 500);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "District",
        accessor: "district_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Block",
        accessor: "block_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "UDISE",
        accessor: "udise_code",
        // Cell: ({ value, row }) => {
        //   return (
        //     <>
        //       {row.original.class_studying}
        //       {"-"}
        //       {row.original.class_section}
        //     </>
        //   );
        // },
      },
      {
        Header: "School",
        accessor: "school_name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Salary",
        accessor: "salary",
        Cell: ({ value, row }) => {
          return <>₹{value ? commaValue(Number(value).toFixed(2)) : ""}</>;
        },
      },
      {
        Header: "Other Expenditure",
        accessor: "other_expenditure",
        Cell: ({ value, row }) => {
          return <>₹{value ? commaValue(Number(value).toFixed(2)) : ""}</>;
        },
      },
      {
        Header: "Total Expense",
        accessor: "total",
        Cell: ({ value, row }) => {
          return <>₹{value ? commaValue(Number(value).toFixed(2)) : ""}</>;
        },
      },
      {
        Header: "Submitted Date",
        accessor: "formSubmittedDate",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Response",
        accessor: "budget_feasibile",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <p>{value}</p>
              {value == "no" && (
                <IoMdEye
                  className="mb-2 text-danger"
                  onClick={() => getReason(row.original.ifno_remarks)}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Action",
        accessor: "ceo_approval_sts",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <p onClick={() => handlUpdate(row.original)}>
                {value == "rejected" ? (
                  <IoMdClose fontSize={25} />
                ) : value == "approved" ? (
                  <TiTick fontSize={25} />
                ) : (
                  <MdEdit fontSize={25} />
                )}
              </p>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="main">
        {/* FMS Program option starts */}

        <Modal
          centered
          show={show}
          onHide={() => setShow(false)}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div className="mt-3 mb-3 d-flex justify-content-start">
              <textarea
                className="form-control w-100"
                name="Reason"
                rows={4}
                placeholder=""
                id="phone"
                required
                value={reasons}
              />
            </div>
            <div className="px-2">
              <button
                type="button"
                onClick={() => {
                  setShow(false);
                  setReasons("");
                }}
                className="btn btn-danger"
              >
                cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <FmsDrlModal
          show={modalShow}
          data={data}
          details={details}
          setDetails={setDetails}
          getList={getList}
          onHide={() => setModalShow(false)}
        />

        <section className="fms_section mar_70">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="title_section_emis text-center">
                  <h4>FMS APPLICATIONS</h4>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <div className="d-flex gap-2 align-items-end">
                    <div>
                      <label className="form-label">School UDISE</label>
                      <input
                        type="text"
                        onChange={(e) => setUdise(e.target.value)}
                        value={udise}
                        placeholder="Enter the UDISE"
                        className="mb-0 form-control "
                        style={{
                          width: "100%",
                          height: "40px",
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      style={{
                        padding: ".375375rem .875rem",
                        fontSize: ".875rem",
                        height: "40px",
                        color: "#fff",
                        background: "green",
                        border: "1px solid green",
                        borderRadius: "10px",
                      }}
                      onClick={() => handleAdd()}
                    >
                      Add
                    </button>
                  </div>

                  <div className="d-flex ">
                    <button
                      style={{
                        padding: ".375375rem .875rem",
                        fontSize: ".875rem",
                        color: "#333",
                        height: "40px",
                        borderRadius: "10px",
                        background: "#ffba01",
                        border: "1px solid #ffba01",
                      }}
                      type="button"
                      onClick={() => navigate("/FMS_program")}
                    >
                      Back
                    </button>
                  </div>
                </div>
                <div className="table_section ">
                  <CustomTable
                    columns={columns}
                    data={list}
                    searchBox={"hide"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default FMSApplication;
