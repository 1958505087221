import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "./GlobalFilter";

import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
  } from "react-table";


  const CustomTable=({ columns, data, searchBox })=> {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
  
      globalFilter,
      setGlobalFilter,
  
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination
    );
  
    return (
      <>
        <Row className="mb-3 table-show row">
          {/* <Col className="d-flex align-items-center">
            <p>Show</p>
  
            <select
              className="form-control w-auto mx-2 p-2 py-1"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <p>entries</p>
          </Col> */}
          <Col className="d-flex justify-content-end">
          {searchBox !== 'hide' &&  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
            {/* <Link to="/support/add-support">
              <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
                <i className="feather icon-plus" /> Add Ticket
              </Button>
            </Link> */}
          </Col>
        </Row>
        <BTable size="sm"  hover responsive {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, ind) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={ind}>
                {headerGroup.headers.map((column, ind2) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={ind2}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="feather icon-arrow-down text-muted float-right" />
                        ) : (
                          <span className="feather icon-arrow-up text-muted float-right" />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, ind3) => {
                    return (
                      <td {...cell.getCellProps()} key={ind3}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
        <Row className="justify-content-between table-show my-2">
          {/* <Col>
            <span className="d-flex align-items-center">
              <p className="px-1">Page</p>{" "}
              <strong>
                {" "}
                {pageIndex + 1} of {pageOptions.length}{" "}
              </strong>{" "}
              <p className="px-1">|</p>
              <p className="px-1">Go to page:</p>{" "}
              <input
                type="number"
                className="form-control ml-2  p-1 py-1 text-center"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "40px" }}
              />
            </span>
          </Col> */}
          <Col>
            <Pagination className="justify-content-end ">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      </>
    );
  }

  export default CustomTable