import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fmsMobileMaster,
  fmsMobileNewUser,
  fmsMobileSendOTP,
  fmsMobileVerifyOTP,
} from "../constants/Services/Api/api";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import Footer from "../Layout/Footer";

const FMSLogin = () => {
  const navigate = useNavigate();
  const [localBodyTypeList, setLocalBodyTypeList] = useState([]);
  const [localBodyTypeLists, setLocalBodyTypeLists] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [localBodyType, setLocalBodyType] = useState("");
  const [designation, setDesignation] = useState("");
  const [district, setDistrict] = useState("");
  const [block, setBlock] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [localBodyTypeErr, setLocalBodyTypeErr] = useState(false);
  const [designationErr, setDesignationErr] = useState(false);
  const [districtErr, setDistrictErr] = useState(false);
  const [blockErr, setBlockErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [numberErr, setNumberErr] = useState(false);
  const [verify, setVerify] = useState(false);
  const [btn, setBtn] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Thirukural_img, setThirukural_img] = useState("");

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const getMasterFMS = (flag, lclbdy_type, dist_id) => {
    let params = `?flag=${flag}`;

    if (lclbdy_type) {
      params += `&lclbdy_type=${lclbdy_type}`;
    }

    if (dist_id) {
      params += `&dist_id=${dist_id}`;
    }

    fmsMobileMaster(params)
      .then((res) => {
        console.log("res", res);

        // flag = 1
        if (flag == 1) {
          setLocalBodyTypeLists(res?.result);
          const uniqueArray = res?.result?.filter(
            (obj, index, self) =>
              index ===
              self.findIndex((t) => t.localbody_type === obj.localbody_type)
          );
          console.log(uniqueArray);
          setLocalBodyTypeList(uniqueArray);
        } else if (flag == 2) {
          let design_ = localBodyTypeLists?.filter(
            (i) => i?.localbody_type == lclbdy_type
          );
          setDesignationList(design_);
          setDistrictList(res?.result);
          setLocalBodyType(lclbdy_type);
        } else if (flag == 3) {
          setDistrict(dist_id);
          setBlockList(res?.result);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSubmit = () => {
    if (
      name?.length == 0 ||
      email?.length == 0 ||
      localBodyType?.length == 0 ||
      designation?.length == 0 ||
      district?.length == 0 ||
      number?.length == 0
    ) {
      // setNameErr(true)
      // setEmailErr(true)
      // setLocalBodyTypeErr(true)
      // setDesignationErr(true)
      // setDistrictErr(true)
      // setNumberErr(true)
      toast.error("Please Fil All Details");
    } else {
      if(verify){
      let payload = {
        records: {
          district_id: district,
          desigination_id: designation,
          name: name,
          email_id: email,
          phone_no: number,
          id:""
        },
      };
      if(block){
        payload.records.block_id = block
      }
      setBtn(true);
      fmsMobileNewUser(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            navigate('/FMS_application', {state:{userId:res?.user_id}})
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });

      }else{
        toast.error('Please Verify Mobile No'); 
      }
      // toast.success('test')
    }
  };

  const getOTP = () => {
    let payload = {
      records: {
        mob: number,
        flag: "1",
      },
    };
    if (number?.length < 10) {
      toast.error("Please Enter Mobile Number");
    } else {
      setBtn(true);
      fmsMobileSendOTP(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            handleShow();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const handleVerifyOTP = () => {
    if (otp?.length < 6) {
      toast.error("Please Enter OTP");
    } else {
      let payload = {
        records: {
          mob: number,
          otp: otp,
          flag: "1",
        },
      };
      setBtn(true);
      fmsMobileVerifyOTP(payload)
        .then((res) => {
          setBtn(false);
          if (res?.dataStatus) {
            toast.success(res?.message);
            handleClose();
            setVerify(true);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getMasterFMS(1);
  }, []);

  return (
    <>
      <Modal centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>We sent a code to xxxxxx{number?.slice(-4)}</p>
          <div className="mt-3 mb-3 d-flex justify-content-center">
            <input
              type="number"
              className="form-control w-50"
              name="otp"
              placeholder=""
              id="phone"
              required
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
              maxLength={6}
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 6) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 6) {
                  e.target.setCustomValidity("");

                  setOtp(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
            />
          </div>
          <div className="px-2">
            <button
              onClick={() => handleVerifyOTP()}
              className="a_blue"
              disabled={btn}
            >
              Verify
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>FMS Profile Registration</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* FMS Program option starts */}

        <section className="fms_section mar_100">
          <div className="container">
            <div className="fms_card fms_login text-center w_700 flex-col">
              <h2>FMS Profile Registration</h2>
              <div>
                <div className="">
                  <input
                    type="name"
                    placeholder="Name"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="" >
                  <input
                    type="email"
                    placeholder="Enter you Email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <div style={{position:'relative'}}>
                  {validEmail.test(email) == false && email?.length > 1 && (
                    <p className="err-input pe-3">
                      Enter Valid Email
                    </p>
                  )}
                  </div>
                </div>
                <div className="">
                  <select
                    class="form-control form-select"
                    name=""
                    required
                    onChange={(e) => getMasterFMS(2, e.target.value)}
                    value={localBodyType}
                  >
                    <option selected disabled value={""}>
                      Local Body Type
                    </option>
                    {localBodyTypeList?.map((item, ind) => {
                      return (
                        <option value={item?.localbody_type} key={ind}>
                          {item?.localbody_type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="">
                  <select
                    class="form-control form-select"
                    name=""
                    required
                    onChange={(e) => setDesignation(e.target.value)}
                    value={designation}
                  >
                    <option selected disabled value={""}>
                      Select a Designation
                    </option>
                    {designationList?.map((item, ind) => {
                      return (
                        <option value={item?.id} key={ind}>
                          {item?.designation}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="">
                  <select
                    class="form-control form-select"
                    name=""
                    required
                    onChange={(e) => getMasterFMS(3, "", e.target.value)}
                    value={district}
                  >
                    <option selected disabled value={""}>
                      Select a District
                    </option>
                    {districtList?.map((item, ind) => {
                      return (
                        <option value={item?.id} key={ind}>
                          {item?.district_name} / {item?.district_name_tamil}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {localBodyType?.toLocaleLowerCase() == "village panchayat" && (
                  <div className="">
                    <select
                      class="form-control form-select"
                      name=""
                      required
                      onChange={(e) => setBlock(e.target.value)}
                      value={block}
                    >
                      <option selected value={""} disabled>
                        Select a Block
                      </option>
                      {blockList?.map((item, ind) => {
                        return (
                          <option value={item?.id} key={ind}>
                            {item?.block_name} / {item?.block_name_tamil}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                <div className="d-flex gap-2">
                  <input
                    type="number"
                    className="form-control"
                    name="mobile"
                    placeholder="Enter you Phone number "
                    id="phone"
                    maxlength="10"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setNumber(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    required
                    onChange={(e) => setNumber(e.target.value)}
                    value={number}
                  />
                  <div>
                    {verify ? (
                      <button
                        style={{ width: "100%" }}
                        className="a_blue text-nowrap"
                      >
                        Verified
                      </button>
                    ) : (
                      <button
                        // href="/#/FMS_otp"
                        onClick={() => getOTP()}
                        style={{ width: "100%" }}
                        className="a_blue text-nowrap"
                        disabled={btn}
                      >
                        Get OTP
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-2">
                <button
                  onClick={() => handleSubmit()}
                  className="a_blue"
                  disabled={btn}
                >
                  submit
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default FMSLogin;
