import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar";

const Layout = () => {
  return (
    <div className="">
      <TopBar />
      <div className="dashboard">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
