import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import {
  navigationData,
  templeteDetailsData,
} from "../constants/Services/Api/api";
import ImagePopup from "./ImagePopup";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const EnnumEzhuthum = () => {
  (function () {
    "use strict";

    // define variables
    var items = document.querySelectorAll(".timeline li");

 

    // check if an element is in viewport
    // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function callbackFunc() {
      for (var i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    }

    // listen for events
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);
  })();

  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [thirukural_img, setThirukural_img] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => setShowPopup(false);
  const [image_path, setImage_path] = useState("");

  const getImg = (image_) => {
    setImage_path(image_);
    setShowPopup(true);
  };

  // console.log('navigationDataListnavigationDataList', navigationDataList);

  const getTemplate = (navigation_slug, type) => {
    let payload = new FormData();
    payload.append("navigation_slug", navigation_slug);
    templeteDetailsData(payload)
      .then((res) => {
        let data = res?.response;
        let temp = { ...data };

        if (data?.banner_json) {
          let banner_json = { ...data?.banner_json };
          if (languages == 2) {
            banner_json.title = data?.banner_json?.tamil_title;
          } else {
            banner_json.title = data?.banner_json?.eng_title;
          }
          temp.banner_json = banner_json;
        }

        // TAB 2

        if (data?.gallery_json) {
          let history_json = { ...data?.gallery_json };

          if (languages == 2) {
            history_json.name = data?.gallery_json?.gallery_tamil_name;
          } else {
            history_json.name = data?.gallery_json?.gallery_eng_name;
          }
          temp.gallery_json = history_json;
        }

        if (data?.overview_json) {
          let history_json = { ...data?.overview_json };
          if (languages == 2) {
            history_json.title = data?.overview_json?.tamil_title;
            history_json.desc = data?.overview_json?.tamil_desc;
          } else {
            history_json.title = data?.overview_json?.eng_title;
            history_json.desc = data?.overview_json?.eng_desc;
          }
          temp.overview_json = history_json;
        }

        if (data?.mission_json) {
          let history_json = { ...data?.mission_json };
          let commitment_lists_ = Object.values(
            data?.mission_json?.timeline_list
          )?.sort((a, b) => a.timeline_order - b.timeline_order);
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
              commitment_.desc = item?.tamil_desc;
            } else {
              commitment_.title = item?.eng_title;
              commitment_.desc = item?.eng_desc;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...history_json,
          };

          if (languages == 2) {
            commitment.mTitle = data?.mission_json?.tamil_mission_title;
            commitment.vTitle = data?.mission_json?.tamil_vision_title;
            commitment.mDesc = data?.mission_json?.tamil_mission;
            commitment.vDesc = data?.mission_json?.tamil_vision;
          } else {
            commitment.mTitle = data?.mission_json?.eng_mission_title;
            commitment.vTitle = data?.mission_json?.eng_vision_title;
            commitment.mDesc = data?.mission_json?.eng_mission;
            commitment.vDesc = data?.mission_json?.eng_vision;
          }

          commitment.timeline_list = commitment_lists;
          commitment.mission_images = Object.values(
            data?.mission_json?.mission_images
          );
          temp.mission_json = commitment;
        }

        //  console.log('temp', temp);

        setNavigationDataList(temp);

        // to={item?.template_type == 2 ? "/about_us"
        //   :'/'
        //  }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 5);
        if (finder?.id) {
          getTemplate(finder?.slug);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.slug) {
      getTemplate(location?.state?.data?.slug);
    } else {
      getNavigation();
    }
  }, []);

  // console.log('navigationDataList', navigationDataList);

  const timeLiner = [
    {
      time: "011",
      title:
        "3 Subjects in the mission: Tamil, English and Maths ( with integrated themes from EVS )",
    },
    {
      time: "022",
      title: "3 Classes: Class 1,2 and 3",
    },
    {
      time: "033",
      title:
        "Subject-wise and levelled ennum ezhuthum workbooks and activities",
    },
  ];





  return (
    <>
      <ImagePopup
        showPopup={showPopup}
        handleClose={handleClose}
        image_path={image_path}
      />
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>{navigationDataList?.banner_json?.title}</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- ennum ezhuthum starts --> */}

        <section className="ennum_ezhuthum ">
          <div className="container mar_100">
            <div className="title_section text-center">
              <h2>{navigationDataList?.overview_json?.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: navigationDataList?.overview_json?.desc,
                }}
              ></p>
            </div>
          </div>

          <div className="mission_section mar_100">
            {/* <div className="title_section text-center">
              <h2>{navigationDataList?.mission_json?.title}</h2>
            </div>
            <div className="d-flex justify-content-md-between justify-content-center mt_30 mis_img">
              {navigationDataList?.mission_json?.missions?.map((item, ind) => {
                return (
                  <div className="mission_left" key={ind}>
                    <img src={item?.image_path} alt="" />
                    <div className="mission_leftcon">
                      <p>{item?.title}</p>
                    </div>
                  </div>
                );
              })}
            </div> */}

            {/*
            <div className="pos_rel container">
              <div className="title_section text-center">
                <h2 className="txt_wh">
                  Empowering Young Minds, One Skill at a time
                </h2>
              </div>
              <div className="d-flex-jc-ac mt_50">
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <div className="miss_le">
                      <div className="mission_img mis_1">
                        <img src="/assets/img/miss_img1.png" alt="" />
                      </div>
                      <div className="mission_img mis_2">
                        <img src="/assets/img/miss_img2.png" alt="" />
                      </div>
                    </div>
                    <div className="mission_img mis_3">
                      <img src="/assets/img/miss_img3.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 ps-lg-5 ps-0">
                  <div className="mission_content">
                    <div className="mission_card mt-4">
                      <div className="d-flex align-items-center mb-3">
                        <img src="/assets/img/mission_icon.png" alt="" />
                        <h4>Our Mission</h4>
                      </div>
                      <p>
                        By 2025, All Primary Children in Government and Aided
                        Schools in Tamil Nadu will attain basic literacy &
                        Numeracy Skills
                      </p>
                    </div>
                    <div className="mission_card mt-4">
                      <div className="d-flex align-items-center mb-3">
                        <img src="/assets/img/vision_icon.png" alt="" />
                        <h4>Our Vision</h4>
                      </div>
                      <p>
                        Conduct Ennum Ezhuthum in a mission mode from 2022-23.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
            <div className="pos_rel container">
              <div className="title_section text-center">
                <h2 className="txt_wh">
                  Empowering Young Minds, One Skill at a time
                </h2>
              </div>
              <div className="d-flex-jc-ac mt_50">
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <div className="miss_le">
                      <div className={`mission_img mis_1`}>
                        <img
                          src={
                            navigationDataList?.mission_json
                              ?.mission_images?.[0]?.image_path
                          }
                          alt=""
                        />
                      </div>
                      <div className={`mission_img mis_2`}>
                        <img
                          src={
                            navigationDataList?.mission_json
                              ?.mission_images?.[1]?.image_path
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mission_img mis_3">
                      <img
                        src={
                          navigationDataList?.mission_json?.mission_images?.[2]
                            ?.image_path
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 ps-lg-5 ps-0">
                  <div className="mission_content">
                    <div className="mission_card mt-4">
                      <div className="d-flex align-items-center mb-3">
                        <img src="/assets/img/mission_icon.png" alt="" />
                        <h4>{navigationDataList?.mission_json?.mTitle}</h4>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: navigationDataList?.mission_json?.mDesc,
                        }}
                      ></p>
                    </div>
                    <div className="mission_card mt-4">
                      <div className="d-flex align-items-center mb-3">
                        <img src="/assets/img/vision_icon.png" alt="" />
                        <h4>Our Vision</h4>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: navigationDataList?.mission_json?.vDesc,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mar_100">
            <div className="title_section text-center">
              <h2>{navigationDataList?.gallery_json?.name}</h2>
            </div>
            <section className="gallery_section mt_30">
              <div className="gallerys">
                <div className="d-flex-jc-ac">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.top1_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(navigationDataList?.gallery_json?.top1_image)
                          }
                        >
                          {/* <a
                            href={navigationDataList?.gallery_json?.top1_image}
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.top2_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(navigationDataList?.gallery_json?.top2_image)
                          }
                        >
                          {/* <a
                            href={navigationDataList?.gallery_json?.top2_image}
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.top3_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(navigationDataList?.gallery_json?.top3_image)
                          }
                        >
                          {/* <a
                            href={navigationDataList?.gallery_json?.top3_image}
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img
                        src={navigationDataList?.gallery_json?.left_center}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(
                              navigationDataList?.gallery_json?.left_center
                            )
                          }
                        >
                          {/* <a
                            href={navigationDataList?.gallery_json?.left_center}
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img
                        src={navigationDataList?.gallery_json?.center}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(navigationDataList?.gallery_json?.center)
                          }
                        >
                          {/* <a
                            href={navigationDataList?.gallery_json?.center}
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img
                        src={navigationDataList?.gallery_json?.right_center}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(
                              navigationDataList?.gallery_json?.right_center
                            )
                          }
                        >
                          {/* <a
                            href={
                              navigationDataList?.gallery_json?.right_center
                            }
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.bottom1_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(
                              navigationDataList?.gallery_json?.bottom1_image
                            )
                          }
                        >
                          {/* <a
                            href={
                              navigationDataList?.gallery_json?.bottom1_image
                            }
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.bottom2_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(
                              navigationDataList?.gallery_json?.bottom2_image
                            )
                          }
                        >
                          {/* <a
                            href={
                              navigationDataList?.gallery_json?.bottom2_image
                            }
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img
                        src={navigationDataList?.gallery_json?.bottom3_image}
                        alt=""
                      />
                      <div className="gallery_view">
                        <div
                          className="org_btn"
                          onClick={() =>
                            getImg(
                              navigationDataList?.gallery_json?.bottom3_image
                            )
                          }
                        >
                          {/* <a
                            href={
                              navigationDataList?.gallery_json?.bottom3_image
                            }
                            className="image"
                            title=""
                          > */}
                          <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          {/* </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* <!-- ennum ezhuthum ends --> */}

        {/* <!-- timeline starts --> */}

        <div class="time_line mar_100">
          <div class="title_section text-center">
            <h2>Timeline</h2>
            {/* <h2>Scope of the ennum ezhuthum mission</h2> */}
            {/* <p>Key Milestones and Objectives</p> */}
          </div>


          
    <VerticalTimeline>
    {
            navigationDataList?.mission_json?.timeline_list?.map((data,ind) =>  (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                // date="2011 - present"
                iconStyle={{ background: '#000', color: '#fff',  }}
                key={ind}
              >
                <h3 className="vertical-timeline-element-title">{data?.title}</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                  <p
                    className="text-light"
                    dangerouslySetInnerHTML={{ __html: data?.desc }}
                  />
              </VerticalTimelineElement>
            ))
          }
    </VerticalTimeline>


          {/* <section class="timeline">
            <ul>
              {navigationDataList?.mission_json?.timeline_list?.map(
                (item, ind) => {
                  return (
                    <li key={ind}>
                      <div>
                        <time>{item?.title}</time>
                        <p
                          className="text-light"
                          dangerouslySetInnerHTML={{ __html: item?.desc }}
                        />
                      </div>
                    </li>
                  );
                }
              )}  


              <li>
                <div>
                  <time>01</time>3 Subjects in the mission: Tamil, English and
                  Maths ( with integrated themes from EVS )
                </div>
              </li>

              <li>
                <div>
                  <time>02</time>3 Classes: Class 1,2 and 3
                </div>
              </li>

              <li>
                <div>
                  <time>03</time>
                  Subject-wise and levelled ennum ezhuthum workbooks and
                  activities
                </div>
              </li>
            </ul>
          </section> */}
        </div>

        {/* <!-- timeline ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default EnnumEzhuthum;
