import React, { useState } from 'react';

const CcPaymentForm = ({ encryptData, accessCode }) => {
  const [formData, setFormData] = useState({
    encRequest: encryptData,
    access_code: accessCode,
  });

  // Handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    form.submit(); // Submitting the form programmatically
    // alert('righgt/')
  };

  console.log('formData', formData);

  return (
    <form
      className="d-none"
      id="ccPayment"
      method="post"
      name="redirect"
      action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
      onSubmit={handleSubmit}
    >   
      <input
        type="hidden"
        placeholder="encryptData"
        id="encRequest"
        name="encRequest"
        value={formData.encRequest}
      />
      <input
        type="hidden"
        placeholder="accessCode"
        name="access_code"
        id="access_code"
        value={formData.access_code}
      />
    </form>
  );
};

export default CcPaymentForm;
