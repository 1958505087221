import React from "react"
import Modal from "react-bootstrap/Modal"

const ImagePopup = ({ showPopup, handleClose, image_path, popType }) => {
  // console.log('image_path', image_path);

  return (
    <>
      <Modal
        show={showPopup}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {popType == "video" ? (
            <div className="w-100" style={{width:'500px'}}>
              <iframe
                className="w-100 text-center"
                
                src={
                  image_path?.name
                    ? URL.createObjectURL(image_path)
                    : image_path
                }
              />
            </div>
          ) : (
            <div className="w-100">
              <img
                className="w-100 text-center"
                src={
                  image_path?.name
                    ? URL.createObjectURL(image_path)
                    : image_path
                }
              />  
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ImagePopup
