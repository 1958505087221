import { useState, useEffect } from "react";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./css/style.css";
import "./css/responsive.css";
import "./css/fontawesome.min.css";
import "./css/bootstrap.min.css";
import ReactRoutes from "./Routes";
import httpClient from "./constants/HttpClient/httpClient";
import { Spinner } from "react-bootstrap";
import { Toaster } from "react-hot-toast";

function App() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
    <Toaster
      position="top-right"
      reverseOrder={false}/>
        {loading && 
        <div style={{zIndex:1500, position:"fixed", top:"45%", left:'49%'}}>
        <Spinner animation="border" variant="primary"/>
        </div>}
      <HashRouter>
        <ReactRoutes />
      </HashRouter>
    </>
  );
}

export default App;
