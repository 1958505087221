import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import {
  magazineGet,
  navigationData,
  templeteDetailsData,
} from "../constants/Services/Api/api";
import Carousel from "react-bootstrap/Carousel";

const Magazine = () => {
  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [thirukural_img, setThirukural_img] = useState("");
  const [magazineList, setMagazineList] = useState([]);

  const getTemplate = (navigation_slug, type) => {
    let payload = new FormData();
    payload.append("navigation_slug", navigation_slug);
    templeteDetailsData(payload)
      .then((res) => {
        let data = res?.response;
        let temp = { ...data };

        if (data?.banner_json) {
          let banner_json = { ...data?.banner_json };
          if (languages == 2) {
            banner_json.title = data?.banner_json?.tamil_title;
          } else {
            banner_json.title = data?.banner_json?.eng_title;
          }
          temp.banner_json = banner_json;
        }

        // TAB 2

        if (data?.overview_json) {
          let banner_json = { ...data?.overview_json };
          if (languages == 2) {
            banner_json.title = data?.overview_json?.tamil_title;
            banner_json.desc = data?.overview_json?.tamil_desc;
          } else {
            banner_json.title = data?.overview_json?.eng_title;
            banner_json.desc = data?.overview_json?.eng_desc;
          }
          temp.overview_json = banner_json;
        }

        if (data?.subscription_json) {
          let banner_json = { ...data?.subscription_json };
          if (languages == 2) {
            banner_json.title = data?.subscription_json?.tamil_title;
            banner_json.desc = data?.subscription_json?.tamil_desc;
            banner_json.points = data?.subscription_json?.tamil_points;
          } else {
            banner_json.title = data?.subscription_json?.eng_title;
            banner_json.desc = data?.subscription_json?.eng_desc;
            banner_json.points = data?.subscription_json?.eng_points;
          }
          temp.subscription_json = banner_json;
        }

        if (data?.magazine_reach_json) {
          let history_json = { ...data?.magazine_reach_json };
          let commitment_lists_ = Object.values(
            data?.magazine_reach_json?.programs
          );
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
              commitment_.schools = item?.tamil_schools;
              commitment_.reach = item?.tamil_reach;
              commitment_.description = item?.tamil_description;
            } else {
              commitment_.title = item?.eng_title;
              commitment_.schools = item?.eng_schools;
              commitment_.reach = item?.eng_reach;
              commitment_.description = item?.eng_description;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...history_json,
          };

          if (languages == 2) {
            commitment.title = data?.magazine_reach_json?.tamil_title;
          } else {
            commitment.title = data?.magazine_reach_json?.eng_title;
          }

          commitment.programs = commitment_lists;
          temp.magazine_reach_json = commitment;
        }

        //  console.log('temp', temp);
        setNavigationDataList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getMagazineList = () => {
    magazineGet()
      .then((res) => {
        let temp = [];
        res?.magazine_list?.map((item, ind) => {
          let obj = { ...item };
          if (languages == 2) {
            obj.name_ = item?.tName;
            if(item?.notes !== 0){
              obj.grade_ = item?.notes_tamil;
            }else{
              obj.grade_ = item?.grade_tamil;
            }
          } else {
            obj.name_ = item?.name;
            if(item?.notes !== 0){
              obj.grade_ = item?.notes;
            }else{
              obj.grade_ = item?.grade;
            }
          }
          temp.push(obj);
        });
        let arr = temp?.slice(0, 3)
        setMagazineList(arr);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getMagazineList();
  }, []);

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 4);
        if (finder?.id) {
          getTemplate(finder?.slug);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.slug) {
      getTemplate(location?.state?.data?.slug);
    } else {
      getNavigation();
    }
  }, []);

  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>{navigationDataList?.banner_json?.title}</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- magazine starts --> */}

        <div className="magazine_sec mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>{navigationDataList?.overview_json?.title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: navigationDataList?.overview_json?.desc,
                }}
              ></p>
            </div>
          </div>
        </div>

        <div className="sub_detail mar_100">
          <div className="d-flex-jc container">
            <div className="col-lg-6 col-12 pe-lg-4 pe-0">
              <h3>{navigationDataList?.subscription_json?.title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: navigationDataList?.subscription_json?.desc,
                }}
              ></p>
            </div>
            <div className="col-lg-6 col-12 ">
              <div className="sub_card">
                <ul>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: navigationDataList?.subscription_json?.points,
                    }}
                  ></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="tamil_maganize mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>Tamil Magazines</h2>
            </div>
            <div className="d-flex-jc">
              {magazineList?.map((item, ind) => {
                return (
                  <div className="col-md-4 col-sm-6 col-12 mt-5" key={ind}>
                    <div className="maga_card">
                      <div className="maga_img">
                        <img
                          src={item?.bucket_url}
                          // src={"/assets/img/Thenchittu.png"}
                          alt=""
                        />
                      </div>
                      <h4>{item?.name_}</h4>
                      <div className="d-flex justify-content-between">
                        <h6>{item?.grade_}</h6>
                        <h6>
                          Yearly cost <span>RS.{item?.uniCost}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="select_sub text-center mt_50">
              <h5 >Note : </h5>
              <p className="text-center">
                Subscribers can sign up for the magazine untill the 24th of any
                given month and will receive their first issue in the following
                month. However, subscribers who subscribe after 24th will have
                to wait until the month after next to receive their first
                magazine.{" "}
              </p>
              <div className="btn_new mt-3 mb-4 d-flex justify-content-center">
                <a href="https://tnschools.gov.in/magazine-payment" className="txt_wh">
                  Select Subscription
                </a>
              </div>
              <h6 className="d-flex align-items-center">
                <i className="bx bx-phone"></i>
                <a href="tel:14417">14417</a>
              </h6>
              <h6 className="d-flex align-items-center">
                <i className="bx bx-envelope"></i>
                <a href="mailto:magazine.subscription@tnschools.gov.in">
                  magazine.subscription@tnschools.gov.in
                </a>
              </h6>
            </div>

            <div className="reach_section mt_50">
              <h2>{navigationDataList?.magazine_reach_json?.title}</h2>
              <div className="magazine_scroll">
                <Carousel>
                  {navigationDataList?.magazine_reach_json?.programs?.map(
                    (item, ind) => {
                      return (
                        <Carousel.Item key={ind}>
                          <div className="d-flex-jc-ac">
                            <div className="col-lg-8">
                              <h3>{item?.title}</h3>
                              <h6 className="mb_20">{item?.description}</h6>
                              <div className="maga_count">
                                <div className="d-flex align-items-center mb_20">
                                  <img src="/assets/img/school.png" alt="" />
                                  <p>{item?.schools}</p>
                                </div>
                                <div className="d-flex align-items-center mb_20">
                                  <img src="/assets/img/training.png" alt="" />
                                  <p>{item?.reach}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="maga_ban_img">
                                <img src={item?.image_path} alt="" />
                              </div>
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    }
                  )}
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- magazine ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default Magazine;
