import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Layout/Footer";

const FMSProgram = () => {
  const [Thirukural_img, setThirukural_img] = useState("");
  const navigate = useNavigate()
  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src="/assets/img/history_section_background.webp"
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>FMS Program</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* FMS Program option starts */}

        <section className="fms_section mar_100">
          <div className="container">
            <div className="fms_card text-center">
              <h2>FMS Program</h2>
              <h6>
                Please select whether you are an existing user or a new user
                from the two options below
              </h6>
              <div className="d-flex-jc-ac">
                <div className=" px-2 mt_30">
                  <a href="/#/FMS_newuser" className="a_blue">New User</a>
                </div>
                <div className=" px-2 mt_30">         
                  <a href="/#/FMS_login" className="a_red">Existing User</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FMS program option ends */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default FMSProgram;
