import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Layout/Footer";
import TopBar from "../Layout/TopBar";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import {
  blogDetails,
  blogLIstData,
  navigationData,
  templeteDetailsData,
} from "../constants/Services/Api/api";
import moment from "moment/moment";

const Blog_detail = () => {
  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [thirukural_img, setThirukural_img] = useState("");
  const [BlogD, setBlogD] = useState({});
  const [bloglist, setbloglist] = useState([]);
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleBlog = (slug) => {
    var formData = new FormData();
    formData.append("blog_slug", slug);
    let payload = {
      blog_slug: slug,
    };
    blogDetails(payload)
      .then((res) => {
        let data = res?.data;
        let temp = {
          ...data,
        };

        if (languages == 2) {
          temp.title = data?.tamil_title;
          temp.blog_by = data?.tamil_blog_by;
          temp.description = data?.tamil_description;
          temp.conclusion_json = {
            ...data?.conclusion_json,
            intro: data?.conclusion_json?.tamil_intro,
          };
          temp.intro_json = {
            ...data?.intro_json,
            intro: data?.intro_json?.tamil_intro,
          };
        } else {
          temp.title = data?.eng_title;
          temp.blog_by = data?.eng_blog_by;
          temp.description = data?.eng_description;
          temp.conclusion_json = {
            ...data?.conclusion_json,
            intro: data?.conclusion_json?.eng_intro,
          };

          temp.intro_json = {
            ...data?.intro_json,
            intro: data?.intro_json?.eng_intro,
          };
        }

        if (data?.right_image_json) {
          let right_image_json = {
            ...data?.right_image_json,
            points: [],
          };
          if (languages == 2) {
            right_image_json.title = data?.right_image_json?.tamil_title;
          } else {
            right_image_json.title = data?.right_image_json?.eng_title;
          }

          let arr = Object.values(data?.right_image_json?.points);
          let points = [];
          arr?.map((item, ind) => {
            if (languages == 2) {
              points.push({
                ...item,
                title: item?.tamil_title,
              });
            } else {
              points.push({
                ...item,
                title: item?.eng_title,
              });
            }
          });
          right_image_json.points = points;
          temp.right_image_json = right_image_json;
        }

        if (data?.left_image_json) {
          let left_image_json = {
            ...data?.left_image_json,
            points: [],
          };

          if (languages == 2) {
            left_image_json.title = data?.left_image_json?.tamil_title;
          } else {
            left_image_json.title = data?.left_image_json?.eng_title;
          }

          let arr = Object.values(data?.left_image_json?.points);
          let points = [];
          arr?.map((item, ind) => {
            if (languages == 2) {
              points.push({
                ...item,
                title: item?.tamil_title,
              });
            } else {
              points.push({
                ...item,
                title: item?.eng_title,
              });
            }
          });
          left_image_json.points = points;
          temp.left_image_json = left_image_json;
        }

        if (data?.center_image_json) {
          let center_image_json = {
            ...data?.center_image_json,
            points: [],
          };
          if (languages == 2) {
            center_image_json.title = data?.center_image_json?.tamil_title;
          } else {
            center_image_json.title = data?.center_image_json?.eng_title;
          }
          let arr = Object.values(data?.center_image_json?.points);
          let points = [];
          arr?.map((item, ind) => {
            if (languages == 2) {
              points.push({
                ...item,
                title: item?.tamil_title,
              });
            } else {
              points.push({
                ...item,
                title: item?.eng_title,
              });
            }
          });
          center_image_json.points = points;
          temp.center_image_json = center_image_json;
        }
        
        if(!temp?.blog_id){
          return navigate('/404')
        }
        setBlogD(temp);
      })
      .catch((err) => {
        console.log("err", err);
        navigate('/404')
      });
  };

  const getBlogList = () => {
    blogLIstData()
      .then((res) => {
        const data = res?.map((fog, k) => {
          if (languages == 2) {
            return { ...fog, title: fog.tamil_title, desc: fog.tamil_description};
          } else {
            return { ...fog, title: fog.eng_title, desc: fog.eng_description};
          }
        });

        let finder = data?.filter((i) => i?.slug !== slug);

        setbloglist(finder ?? []);
      })
      .catch((err) => {
        console.log("err", err.message);
      });
  };

  useEffect(() => {
    if (slug) {
      handleBlog(slug);
      getBlogList();
    }
  }, [slug]);

  console.log("bloglist", window.location.href);

  return (
    <>
      <div className="main">
        {/* <TopBar setNavigationDataList={setNavigationDataList}/> */}
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img className="img_topcen" src={BlogD?.blog_image} alt="" />
          <div className="page_content">
            <div className="text-center">
              <h1>Blog Detail</h1>
             
            </div>
          </div>
          
        </div>

        {/* <!-- page banner ends --> */}

        {/* blog list starts */}

        <section className="blog_det_section mar_100">
          <div className="container">
            <div className="d-flex align-items-end">
              <div className="col-lg-7">
                <div className="blog_dettit">
                  <h2>{BlogD?.title}</h2>
                  <h6>
                    by {BlogD?.blog_by}{" "}
                    <span className="px-3">
                      {moment(BlogD?.updated_at).format("MMMM Do YYYY")}
                    </span>{" "}
                    <span className="read_time">
                      {moment(BlogD?.updated_at).fromNow()}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="col-lg-5 blog_shsocial">
                <span>Share </span>
                <div className="bl_social">
                  <div className="soc_ic">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} target="_blank">
                      {" "}
                      <i className="fa-brands fa-facebook-f txt_wh"></i>
                    </a>
                  </div>
                  <div className="soc_ic">
                    <a href={`https://web.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`} target="_blank">
                      {" "}
                      <i className="fa-brands fa-whatsapp txt_wh"></i>
                    </a>
                  </div>
                  <div className="soc_ic">
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`} target="_blank">
                      <i class="fa-brands fa-linkedin txt_wh"></i>
                    </a>
                  </div>
                  <div className="soc_ic">
                    <a href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}`} target="_blank">
                      <i className="fa-brands fa-twitter txt_wh"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="blog_detdes mt_50">
              <h3>{BlogD?.intro_json?.intro}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: BlogD?.description,
                }}
              ></p>
              {/* <h3>What is Samacheer Kalvi?</h3>
              <p>
                Samacheer Kalvi, also known as the Equitable Education System,
                was introduced in 2010 to standardize the curriculum across
                government, private, and matriculation schools in Tamil Nadu.
                Before this reform, different schools followed different
                syllabi, leading to disparities in learning quality and access
                to resources. Under this system, students from all backgrounds
                follow the same syllabus, textbooks, and evaluation methods,
                ensuring a level playing field for every child in the state.
              </p> */}
              {BlogD?.right_image_json?.image_path && (
                <div className="d-flex">
                  <div className="col-lg-6 col-12 pe-lg-3 pe-0">
                    <h3>{BlogD?.right_image_json?.title}</h3>
                    <ul>
                      {BlogD?.right_image_json?.points?.map((item, ind) => {
                        return <li key={ind}>{item?.title}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img
                      // src="/assets/img/blog_img.png"
                      src={BlogD?.right_image_json?.image_path}
                      alt=""
                    />
                  </div>
                </div>
              )}

              {BlogD?.center_image_json?.image_path && (
                <div className="blog_bg_img my-5">
                  <img
                    className="w-100"
                    src={BlogD?.center_image_json?.image_path}
                    alt=""
                  />
                </div>
              )}
              {BlogD?.center_image_json?.image_path && (
                <div>
                  <h3>{BlogD?.center_image_json?.title}</h3>
                  <ul>
                    {BlogD?.center_image_json?.points?.map((item, ind) => {
                      return <li key={ind}>{item?.title}</li>;
                    })}
                  </ul>
                </div>
              )}

              {BlogD?.left_image_json?.image_path && (
                <div className="d-flex mt_30">
                  <div className="col-lg-6 col-12">
                    <img src={BlogD?.left_image_json?.image_path} alt="" />
                  </div>
                  <div className="col-lg-6 col-12 ps-lg-3 ps-0">
                    <h3>{BlogD?.left_image_json?.title}</h3>
                    <ul>
                      {BlogD?.left_image_json?.points?.map((item, ind) => {
                        return <li key={ind}>{item?.title}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              )}

              <h3>{BlogD?.conclusion_json?.intro}</h3>
              <p>
                The Samacheer Kalvi system has been a revolutionary step in
                making education in Tamil Nadu more equitable and accessible. By
                bridging the gap between students from different backgrounds, it
                has empowered countless children with knowledge and skills for a
                brighter future. With continued efforts in technology
                integration and skill-based learning, Tamil Nadu is setting an
                example for inclusive and quality education in India.
              </p>
            </div>

            <div className="blog_dettit  mt_50">
              <h2 className="text-center">Other Blogs</h2>
              <div className="d-flex-jc">
                {bloglist?.map((item, ind) => {
                  return (
                    <div className="col-lg-4 col-sm-6 col-12 mt_30" key={ind}>
                      <div className="blog_card">
                        <div className="blog_thumb_img">
                          <img src={item?.blog_image} alt="" />
                          <div className="blog_thdate">
                            <span>
                              <i class="fa-regular fa-calendar"></i> {moment(item?.updated_at).format("MMMM Do YYYY")}
                            </span>
                          </div>
                        </div>
                        <h3>
                         { item?.title}
                        </h3>
                        <p
                         dangerouslySetInnerHTML={{
                          __html: item?.desc,
                        }}>
                         
                        </p>
                        <div className="d-flex justify-content-between align-item-center my-4">
                          <div className="btn_new">
                            <Link to={`/Blog_detail/${item?.slug}`}>Read more</Link>
                          </div>
                          <div className="read_time">
                            <span>{moment(item?.updated_at).fromNow()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="col-lg-4 col-sm-6 col-12 mt_30">
                  <div className="blog_card">
                    <div className="blog_thumb_img">
                      <img src="/assets/img/blog_img.png" alt="" />
                      <div className="blog_thdate">
                        <span>
                          <i class="fa-regular fa-calendar"></i> December 9,
                          2025
                        </span>
                      </div>
                    </div>
                    <h3>
                      The Evolution of Education in Tamil Nadu: A Journey
                      Through Time
                    </h3>
                    <p>
                      How Tamil Nadu’s education system has transformed over the
                      years, from ancient gurukuls to modern smart classrooms.
                    </p>
                    <div className="d-flex justify-content-between align-item-center my-4">
                      <div className="btn_new">
                        <a href="/#/Blog_detail">Read more</a>
                      </div>
                      <div className="read_time">
                        <span>5 Min Read</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12 mt_30">
                  <div className="blog_card">
                    <div className="blog_thumb_img">
                      <img src="/assets/img/blog_img.png" alt="" />
                      <div className="blog_thdate">
                        <span>
                          <i class="fa-regular fa-calendar"></i> December 9,
                          2025
                        </span>
                      </div>
                    </div>
                    <h3>
                      The Evolution of Education in Tamil Nadu: A Journey
                      Through Time
                    </h3>
                    <p>
                      How Tamil Nadu’s education system has transformed over the
                      years, from ancient gurukuls to modern smart classrooms.
                    </p>
                    <div className="d-flex justify-content-between align-item-center my-4">
                      <div className="btn_new">
                        <a href="/#/Blog_detail">Read more</a>
                      </div>
                      <div className="read_time">
                        <span>5 Min Read</span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        {/* blog list ends */}

        {/* <!-- thirukural ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default Blog_detail;
