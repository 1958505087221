import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../Layout/Footer";
import { navigationData, templeteDetailsData } from "../constants/Services/Api/api";
import CustomTable from "../Layout/CustomTable";
import TableColumns from "./TableColumns";
import TableColumns3 from "./TableColumns3";

const PressRelease = () => {
  const location = useLocation()
  const languages = localStorage.getItem("language")
  const[navigationDataList, setNavigationDataList]=useState({
    header_json:{
      title1:"",
      title2:"",
      title3:""
    },
    act_code_json:[]
  })
  const [thirukural_img, setThirukural_img]=useState("")

      const getTemplate=(navigation_slug, type)=>{
        let payload = new FormData()
        payload.append('navigation_slug', navigation_slug)
        templeteDetailsData(payload).then((res)=>{
          let data = res?.response
          let temp = {...data}


          if (data?.banner_json) {
            let banner_json = { ...data?.banner_json };
            if (languages == 2) {
              banner_json.name = data?.banner_json?.tamil_title;
            } else {
              banner_json.name = data?.banner_json?.eng_title;
            }
            temp.banner_json = banner_json;
          }
    
          if(data?.header_json){
            let banner_json = {...data?.header_json}        
            if(languages == 2){
              banner_json.title1 = data?.header_json?.tamil_title1
              banner_json.title2 = data?.header_json?.tamil_title2
              if(data?.header_json?.tamil_title3){
                banner_json.title3 = data?.header_json?.tamil_title3
              }else{
                banner_json.title3 = ""
              }
              
            }else{
              banner_json.title1 = data?.header_json?.eng_title1
              banner_json.title2 = data?.header_json?.eng_title2
              if(data?.header_json?.eng_title3){
                banner_json.title3 = data?.header_json?.eng_title3
              }else{
                banner_json.title3 = ""
              }                          
            }
            temp.header_json = banner_json
           }
           
            // TAB 2
            if(data?.act_code_json?.act_code){  
              let commitment_lists_ = Object.values(data?.act_code_json?.act_code)            
              let commitment_lists = []
              commitment_lists_?.map((item, ind) => {
                let commitment_ = {...item}
                if(languages == 2){
                  commitment_.desc1 = item?.tamil_desc1
                  commitment_.desc2 = item?.tamil_desc2
                }else{
                  commitment_.desc1 = item?.eng_desc1
                  commitment_.desc2 = item?.eng_desc2
                }
                 commitment_lists.push(commitment_)
              })
              temp.act_code_json = commitment_lists
             }
             //  console.log('temp', temp);  
          setNavigationDataList(temp)
        }).catch((err)=>{
          console.log('err', err);
          
        })
      }
      
          const getNavigation=()=>{
            let payload={    
            }
            navigationData(payload).then((res)=>{ 
              let temp = []
              res?.map((item, ind)=>{
                item?.child_navigations?.map((chid, chind)=>{
                  temp.push(chid)
                })
                
                temp.push(item)
              })
              let finder = temp?.find((i)=>i?.template_type == 9) 
              if(finder?.id){
                getTemplate(finder?.slug)
              }     
            }).catch((err)=>{
              console.log('err', err);
              
            })
          }
        
        useEffect(()=>{ 
          if(location?.state?.data?.slug){
           getTemplate(location?.state?.data?.slug)
          }else{
            getNavigation()
          }
        },[])

        // console.log('navigationDataList', navigationDataList);


  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>{navigationDataList?.banner_json?.name}</h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- press release starts --> */}

        <section className="press_release mar_100">
          <div className="container">
            <div className="d-flex flex-wrap justify-content-lg-between  justify-content-center align-items-center">
              <div className="col-lg-3">
                <h4>Press Release</h4>
              </div>
              {/* <div className="col-lg-9 mt-lg-0 mt-3">
                <div className="d-flex flex-wrap justify-content-lg-end justify-content-center">
                  <div className=" pe-2">
                    <div id="searchform">
                      <input
                        type="text"
                        id="search-bar"
                        autocomplete="off"
                        className="form-control"
                        placeholder="Search"
                      />
                      <ul className="output" style={{ display: "none" }}></ul>
                      <button className="btn-sea" type="submit">
                        <i className="fa fa-search sea_ic bg_none"></i>
                      </button>
                    </div>
                  </div>
                  <div className=" pe-2">
                    <button className="btn_outli filter_bt bg_none">
                      <i className="ri-filter-3-line fil_ic"></i>
                      Filter
                    </button>
                  </div>
                  <div className=" pe-2">
                    <button className="btn_outli export_bt bg_none">
                      <i className="ri-download-cloud-2-line fil_ic"></i> Filter
                    </button>
                  </div>
                  <div className="">
                    <div className="btn_new">
                      <button>+ Add new record</button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="table_section mt_20">
             {navigationDataList?.header_json?.title1 && <TableColumns3 navigationDataList={navigationDataList}/>}
              {/* <table cellspacing="0">
                <tr>
                  <th>Date</th>
                  <th>Subject</th>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>0000-00-00</td>
                  <td>
                    <a href="">
                      Press release - government school students japan foreign
                      tour
                    </a>
                  </td>
                </tr>
              </table> */}
            </div>
          </div>
        </section>

        {/* <!-- press release ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img}/>
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default PressRelease;
