import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import Footer from "../Layout/Footer";
import TopBar from "../Layout/TopBar";
import {
  navigationData,
  templeteDetailsData,
  tnSchoolCount,
} from "../constants/Services/Api/api";
import ImagePopup from "./ImagePopup";
// import { FacebookEmbed } from 'react-social-media-embed';
// import { TwitterTimelineEmbed, TwitterTweetEmbed } from "react-twitter-embed";
// import { FacebookProvider, EmbeddedPost } from 'react-facebook';

const FacebookPage = ({ pageUrl }) => {
  useEffect(() => {
    if (!window.FB) {
      const script = document.createElement("script");
      script.src =
        "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (window.FB) window.FB.XFBML.parse();
      };
      document.body.appendChild(script);
    } else {
      window.FB.XFBML.parse();
    }
  }, [pageUrl]); // Re-renders when pageUrl changes

  return (
    <div
      className="fb-page"
      data-href={pageUrl}
      data-tabs="timeline"
      data-width="340"
      // data-height="500"
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    ></div>
  );
};

const Home = () => {
  const languages = localStorage.getItem("language");
  const location = useLocation();
  const [socialShow, setSocialShow] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const handleClose = () => setShowPopup(false);
  const [image_path, setImage_path] = useState("");

  const handlef = () => {
    fetch(
      "https://graph.facebook.com/v16.0/559290830607620/posts?access_token=3987698784883161|kLmdl4Qgr_-SXeh-WYFoAn1W-C8"
    )
      .then((response) => response.json())
      .then((data) => {
        let posts = data.data;
        let feedContainer = document.getElementById("facebook-feed");

        posts.forEach((post) => {
          let postElement = document.createElement("div");
          postElement.innerHTML = <p>${post.message}</p>;
          feedContainer.appendChild(postElement);
        });
      })
      .catch((error) => console.error("Error fetching Facebook feed:", error));
  };

  // const fetchTweets = async (userId) => {
  //   const url = `https://api.twitter.com/2/users/${userId}/tweets`;

  //   try {
  //     const response = await fetch(url, {
  //       headers: { Authorization: `Bearer ${BEARER_TOKEN}` }
  //     });
  //     const data = await response.json();
  //     console.log("Tweets:", data);
  //   } catch (error) {
  //     console.error("Error fetching tweets:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTweets()
  // },[])

  const getImg = (image_) => {
    setImage_path(image_);
    // setPopupType(type)
    setShowPopup(true);
  };
  // api.login(data).then((res) => {
  //   httpClient.defaults.headers.common["Authorization"] =
  //   `Bearer ${res?.token}` || "";
  // })

  const newsupdates = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For screens below 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // For screens below 768px (Tablets)
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // For screens below 480px (Mobile)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const achivementsection = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For screens below 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // For screens below 768px (Tablets)
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // For screens below 480px (Mobile)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const quicklinks = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For screens below 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // For screens below 768px (Tablets)
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // For screens below 480px (Mobile)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [thirukural_img, setThirukural_img] = useState("");
  const [thirukural, setThirukural] = useState("");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [galleryimg, setGalleryimg] = useState(null);
  const [counts, setCounts] = useState({});

  console.log("navigationDataList", navigationDataList);

  const getTemplate = (navigation_slug, type) => {
    let payload = new FormData();
    payload.append("navigation_slug", navigation_slug);
    templeteDetailsData(payload)
      .then((res) => {
        let data = res?.response;
        let temp = { ...data };

        if (data?.banner_json?.banner_lists) {
          let objToArr = Object.values(data?.banner_json?.banner_lists);
          let banner_json = [...objToArr];
          let temps = [];
          banner_json?.map((item, ind) => {
            let obj = { ...item };
            if (languages == 2) {
              obj.name = item?.tamil_title;
              obj.sub_title = item?.tamil_tsub_title;
            } else {
              obj.name = item?.eng_title;
              obj.sub_title = item?.eng_sub_title;
            }
            temps.push(obj);
          });

          temp.banner_json = temps;
        } else {
          temp.banner_json = [];
        }

        // TAB 2
        if (data?.empowering_json) {
          let empowering_section = data?.empowering_json;
          let empowering_lists_ = Object.values(
            data?.empowering_json?.empowering_lists
          );
          let empowering_lists = [];
          empowering_lists_?.map((item, ind) => {
            let empowering_ = { ...item };
            if (languages == 2) {
              empowering_.name = item?.emp_tamil_title;
              empowering_.sub_title = item?.emp_tamil_tsub_title;
            } else {
              empowering_.name = item?.emp_eng_title;
              empowering_.sub_title = item?.emp_eng_sub_title;
            }
            empowering_lists.push(empowering_);
          });
          let empowering = {
            ...empowering_section,
          };

          if (languages == 2) {
            empowering.name = empowering_section?.tamil_title;
            empowering.sub_title = empowering_section?.tamil_tsub_title;
          } else {
            empowering.name = empowering_section?.eng_title;
            empowering.sub_title = empowering_section?.eng_sub_title;
          }
          empowering.empowering_lists = empowering_lists;
          temp.empowering_json = empowering;
        }

        if (data?.commitment_json) {
          // TAB 3
          let commitment_section = data?.commitment_json;
          let commitment_lists_ = Object.values(
            data?.commitment_json?.commitment_lists
          );
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.name = item?.comm_tamil_title;
              commitment_.sub_title = item?.comm_tamil_tsub_title;
            } else {
              commitment_.name = item?.comm_eng_title;
              commitment_.sub_title = item?.comm_eng_sub_title;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...commitment_section,
          };

          if (languages == 2) {
            commitment.name = commitment_section?.tamil_title;
            commitment.sub_title = commitment_section?.tamil_tsub_title;
          } else {
            commitment.name = commitment_section?.eng_title;
            commitment.sub_title = commitment_section?.eng_sub_title;
          }
          commitment.commitment_lists = commitment_lists;
          temp.commitment_json = commitment;
        }

        if (data?.gallery_json) {
          // TAB 4
          let gallery_section = data?.gallery_json;
          let gallery_lists_ = Object.values(data?.gallery_json?.gallery_lists);
          let gallery_lists = [];
          gallery_lists_?.map((item, ind) => {
            let gallery_ = { ...item };
            if (languages == 2) {
              gallery_.name = item?.gallery_tamil_name;
            } else {
              gallery_.name = item?.gallery_eng_name;
            }
            gallery_lists.push(gallery_);
          });

          let gallery = {
            ...gallery_section,
          };

          if (languages == 2) {
            gallery.name = gallery_section?.tamil_title;
            gallery.sub_title = gallery_section?.tamil_tsub_title;
          } else {
            gallery.name = gallery_section?.eng_title;
            gallery.sub_title = gallery_section?.eng_sub_title;
          }
          gallery.gallery_lists = gallery_lists;
          temp.gallery_json = gallery;
        }

        if (data?.new_updates_json) {
          // TAB 3
          let commitment_lists_ = Object.values(
            data?.new_updates_json?.new_updates
          );
          let quicksLink = Object.values(data?.new_updates_json?.quick_links);
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
              commitment_.desc = item?.tamil_desc;
            } else {
              commitment_.title = item?.eng_title;
              commitment_.desc = item?.eng_desc;
            }
            commitment_lists.push(commitment_);
          });

          temp.new_updates_json = commitment_lists;
          temp.quick_links = quicksLink;
        }

        setNavigationDataList(temp);
        setGalleryimg(
          temp?.gallery_json?.gallery_lists?.length > 0
            ? temp?.gallery_json?.gallery_lists[0]
            : []
        );
        // to={item?.template_type == 2 ? "/about_us"
        //   :'/'
        //  }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 1);
        if (finder?.id) {
          getTemplate(finder?.slug);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.slug) {
      getTemplate(location?.state?.data?.slug);
    } else {
      getNavigation();
    }
  }, []);

  const getSchoolCount = () => {
    tnSchoolCount()
      .then((res) => {
        setCounts(res?.tnschools_count?.[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getSchoolCount();
  }, []);

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <>
      <ImagePopup
        showPopup={showPopup}
        handleClose={handleClose}
        image_path={image_path}
        // popType={popType}
      />

      <div className="">
        {/* <TopBar setNavigationDataList={setNavigationDataList}/> */}
        {/* <!-- home banner section starts --> */}
        <div className="hom_banner">
          <div id="carousel" className="carousel slide" data-bs-ride="carousel">
            {/* <!-- The slideshow/carousel --> */}
            <div className="carousel-inner">
              {navigationDataList?.banner_json?.map((item, ind) => {
                return item?.type == "video" ? (
                  <div className="carousel-item" key={ind}>
                    <div className="banner_img">
                      <video autoPlay muted loop playsInline controls>
                        <source src={item?.image_path} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                ) : (
                  <div className="carousel-item active" key={ind}>
                    <div className="banner_img banner_overlay">
                      <img src={item?.image_path} alt="" />
                      <div className="banner_content">
                        <div>
                          <h1>{item?.name}</h1>
                          <p>{item?.sub_title}</p>
                          {/* <div className="btn_new mt-3">
                            <a href={item?.redirect_url} target={"_blank"}>
                              Read more
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="carousel-item" data-bs-interval="4000">
                <div className="banner_img">
                  <img
                    src={
                      navigationDataList?.banner_json?.image_path
                        ? navigationDataList?.banner_json?.image_path
                        : "/assets/img/banner_img.png"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="4000">
                <div className="banner_img">
                  <video autoPlay loop muted playsInline>
                    <source
                      src="../assets/img/MK Stalin launches free breakfast scheme.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div> */}
              <div className="pos_contcenter">
                <div className="d-flex-jc-ac w-90">
                  <div className="col-lg-8 col-12">
                    {/* <div className="banner_content">
                      <h1>{navigationDataList?.banner_json?.name}</h1>
                      <p>{navigationDataList?.banner_json?.sub_title}</p>
                      <div className="btn_new mt-3">
                        <a
                          href={navigationDataList?.banner_json?.redirect_url}
                          target={"_blank"}
                        >
                          Read more
                        </a>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-lg-4 col-12 mt-lg-0 mt-5">
                    {!socialShow ? (
                      <div
                        className="social_show"
                        role="button"
                        onClick={() => setSocialShow(true)}
                      >
                        <span className="social_btn" href="">
                          Social Feeds
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {socialShow && (
                      <div className="social_feedb">
                        <button
                          data-bs-dismiss="modal"
                          class="clse_btn"
                          onClick={() => setSocialShow(false)}
                        >
                          <i class="fa-solid fa-xmark "></i>
                        </button>

                        {/* <FacebookPostEmbed postUrl={'https://www.facebook.com/profile.php?id=61561404883299&sk=photos'} /> */}

                        <FacebookPage pageUrl="https://www.facebook.com/383191941542943" />

                        {/* <iframe
                          src={`https://www.instagram.com/tnschoolsedu/embed `}
                          width="320"
                          height="435"
                          class="iframe-without-scroll"
                          frameborder="0"
                          scrolling="no"
                          allowtransparency="true"
                        ></iframe> */}

                        {/* <div className="feed_ban">
                        <img src="/assets/img/banner_img.png" alt="" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center soc_head">
                        <h4>Latest Feed</h4>
                        <a href="" target={"_blank"}>
                          View All
                        </a>
                      </div>
                      <div className="feed_list">
                        <div className="feed_card">
                          <div className="d-flex align-items-center">
                            <div className="col-9 pe-1">
                              <h6 className="txt_red">Instagram</h6>{" "}
                              <span>4 hours ago</span>
                              <p>
                                The Honorable Minister of School Education
                                visited the camp held in Madurai yesterday
                                (29.12.2024)
                              </p>
                            </div>

                            <div className="col-3">
                              <div className="soc_img">
                                <img src="/assets/img/banner_img.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="feed_card">
                          <div className="d-flex align-items-center">
                            <div className="col-9 pe-1">
                              <h6 className="txt_bl">Facebook</h6>{" "}
                              <span>4 hours ago</span>
                              <p>
                                The Honorable Minister of School Education
                                visited the camp held in Madurai yesterday
                                (29.12.2024)
                              </p>
                            </div>

                            <div className="col-3">
                              <div className="soc_img">
                                <img src="/assets/img/banner_img.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="feed_card">
                          <div className="d-flex align-items-center">
                            <div className="col-9 pe-1">
                              <h6 className="txt_red">Instagram</h6>{" "}
                              <span>4 hours ago</span>
                              <p>
                                The Honorable Minister of School Education
                                visited the camp held in Madurai yesterday
                                (29.12.2024)
                              </p>
                            </div>

                            <div className="col-3">
                              <div className="soc_img">
                                <img src="/assets/img/banner_img.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="feed_card">
                          <div className="d-flex align-items-center">
                            <div className="col-9 pe-1">
                              <h6 className="txt_blk">Twitter</h6>{" "}
                              <span>4 hours ago</span>
                              <p>
                                The Honorable Minister of School Education
                                visited the camp held in Madurai yesterday
                                (29.12.2024)
                              </p>
                            </div>

                            <div className="col-3">
                              <div className="soc_img">
                                <img src="/assets/img/banner_img.png" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carousel"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* <!-- home banner section ends --> */}
        {/* <!-- new update section starts --> */}
        <div className="container mar_70">
          <div className="new_update">
            <h3>New Updates</h3>
            <Slider {...newsupdates}>
              {navigationDataList?.new_updates_json?.map((item, ind) => {
                return (
                  <div className="item" key={ind}>
                    <div className="news_card" style={{ width: "95%" }}>
                      <h4>{item?.title}</h4>
                      <p>{item?.desc}</p>
                      <a href={item?.redirect_url} target={"_blank"}>
                        <span>Click here</span>
                      </a>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        {/* <!-- new update section ends --> */}
        {/* <!-- about section starts --> */}
        <div className="tn_about mt-4">
          <div className="container">
            <div className="title_section">
              <div className="d-flex-jc-ac">
                <div className="col-lg-5 col-12 pe-lg-3 pe-0">
                  <h2>
                    {languages == 2
                      ? "மாணவர்கள் முன்னேற்றம் மற்றும் கல்வி மேம்பாடு"
                      : "Education as a Pathway to Growth and Progress"}
                  </h2>
                </div>
                <div className="col-lg-7 col-12">
                  <p className="text_just">
                    {languages == 2
                      ? "எங்கள் அர்ப்பணிப்புடன் செயல்படும் ஆசிரியர்கள் புதுமையான கற்பித்தல் முறைகளை பயன்படுத்தி, மாணவர்கள் ஒவ்வொருவரும் கல்வி, சமூக மற்றும் தனிப்பட்ட வளர்ச்சியில் சிறந்து விளங்க சிறப்பு ஆதரவு வழங்குகிறார்கள். திறனளவான பாடத்திட்டத்துடன் சேர்த்து, மாணவர்களின் முழுமையான வளர்ச்சியை உறுதிப்படுத்த பல்வேறு இணைப்பாட செயல்பாடுகளும் வழங்கப்படுகின்றன. கல்வி என்பது ஒளிமிக்க எதிர்காலத்திற்கான திறவுகோல் என்பதை உணர்ந்து, தமிழ்நாடு அரசு தரமான கல்வியை தனது எதிர்கால குடிமக்களுக்காக முன்னுரிமை அளிக்கிறது."
                      : "Our dedicated educators implement innovative teaching techniques and provide personalized guidance to ensure that every student thrives academically, socially, and personally. Alongside a well-structured curriculum, we offer various extracurricular programs to foster overall development. Understanding that education is the gateway to a prosperous future, the Government of Tamil Nadu remains committed to delivering high-quality education to its future generations."}
                  </p>
                </div>
              </div>
            </div>

            <div className="d-flex-jc ">
              <div className="col-lg-3 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_icon">
                    <img src="/assets/img/student_studying.png" alt="" />
                  </div>
                  <br />
                  <h3>
                    {counts?.total_students
                      ? commaValue(counts?.total_students)
                      : 0}
                  </h3>
                  <p style={{ textAlign: "left" }}>
                    Students Studying in Tamil Nadu
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_icon">
                    <img src="/assets/img/teaching_working.png" alt="" />
                  </div>
                  <br />
                  <h3>
                    {counts?.school_count
                      ? commaValue(counts?.school_count)
                      : 0}
                  </h3>
                  <p style={{ textAlign: "left" }}>
                    Schools throughout Tamil Nadu
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_icon">
                    <img src="/assets/img/student_studying.png" alt="" />
                  </div>
                  <br />
                  <h3>
                    {counts?.teaching_staff_count
                      ? commaValue(counts?.teaching_staff_count)
                      : 0}
                  </h3>
                  <p style={{ textAlign: "left" }}>
                    Teacher Working in Tamil Nadu
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_icon">
                    <img src="/assets/img/dedicted_officiers.png" alt="" />
                  </div>
                  <br />
                  <h3>
                    {counts?.admin_staff ? commaValue(counts?.admin_staff) : 0}
                  </h3>
                  <p style={{ textAlign: "left" }}>
                    Dedicated officers are Working
                  </p>
                </div>
              </div>
              {/*
              <div className="col-xl-3 col-lg-6 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_to">
                    <div className="count_icon">
                      <img src="/assets/img/dedicted_officiers.png" alt="" />
                    </div>
                    <div className="count_aid">
                      <div className="count_con h_line pe-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>

                      <div className="count_con ps-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>
                    </div>
                  </div>
                  <div className="count_too">
                    <div className="count_stu">
                      <div className="count_con h_line pe-2">
                        <h3>250000</h3>
                        <h6>Students</h6>
                      </div>
                      <div className="count_aid">
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5> Overall Studying in Tamil Nadu</h5>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_to">
                    <div className="count_icon">
                      <img src="/assets/img/dedicted_officiers.png" alt="" />
                    </div>
                    <div className="count_aid">
                      <div className="count_con h_line pe-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>

                      <div className="count_con ps-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>
                    </div>
                  </div>
                  <div className="count_too">
                    <div className="count_stu">
                      <div className="count_con h_line pe-2">
                        <h3>250000</h3>
                        <h6>Students</h6>
                      </div>
                      <div className="count_aid">
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5> Overall Studying in Tamil Nadu</h5>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_to">
                    <div className="count_icon">
                      <img src="/assets/img/dedicted_officiers.png" alt="" />
                    </div>
                    <div className="count_aid">
                      <div className="count_con h_line pe-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>

                      <div className="count_con ps-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>
                    </div>
                  </div>
                  <div className="count_too">
                    <div className="count_stu">
                      <div className="count_con h_line pe-2">
                        <h3>250000</h3>
                        <h6>Students</h6>
                      </div>
                      <div className="count_aid">
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5> Overall Studying in Tamil Nadu</h5>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 col-12 pe-sm-3 pe-0  mt-3">
                <div className="count_card">
                  <div className="count_to">
                    <div className="count_icon">
                      <img src="/assets/img/dedicted_officiers.png" alt="" />
                    </div>
                    <div className="count_aid">
                      <div className="count_con h_line pe-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>

                      <div className="count_con ps-2">
                        <h4>250000</h4>
                        <h6>Aided</h6>
                      </div>
                    </div>
                  </div>
                  <div className="count_too">
                    <div className="count_stu">
                      <div className="count_con h_line pe-2">
                        <h3>250000</h3>
                        <h6>Students</h6>
                      </div>
                      <div className="count_aid">
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                        <div className="count_con ps-2">
                          <h4>250000</h4>
                          <h6>Aided</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5> Overall Studying in Tamil Nadu</h5>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
        {/* <!-- about section ends --> */}
        {/* <!-- achievement starts --> */}
        <section className="achievement_section mar_100">
          <div className="container pos_rel">
            <div className="title_section text-center">
              <h2 className="txt_wh">
                {navigationDataList?.empowering_json?.name}
              </h2>
              <p className="txt_wh">
                {navigationDataList?.empowering_json?.sub_title}
              </p>
            </div>

            <div className="achievement_cards">
              <Slider {...achivementsection}>
                {navigationDataList?.empowering_json?.empowering_lists?.map(
                  (item, ind) => {
                    return (
                      <div className="item">
                        <div
                          className="achieve_card"
                          style={{ width: "90%" }}
                          key={ind}
                        >
                          <div className="achieve_img">
                            {/* <img src="/assets/img/banner_img.png" alt="" /> */}
                            <img src={item?.image_path} alt="" />
                          </div>
                          <h3>{item?.name}</h3>
                          <p>{item?.sub_title}</p>
                          {/* <div className="text-center">
                            <div className="btn_new mt_10 mb_20">
                              <a href={item?.redirect_url} target={"_blank"}>
                                Read more
                              </a>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  }
                )}
              </Slider>
            </div>
          </div>
        </section>
        {/* <!-- achievement ends --> */}
        {/* <!-- commitment starts --> */}
        <section className="commitement_section mar_100">
          <div className="container">
            <div className="title_section text-center">
              <h2>{navigationDataList?.commitment_json?.name}</h2>
              <p>{navigationDataList?.commitment_json?.sub_title}</p>
            </div>

            <div className="d-flex-jc">
              {navigationDataList?.commitment_json?.commitment_lists?.map(
                (item, ind) => {
                  return (
                    <div className="col-lg-4 col-12 mt_50" key={ind}>
                      <div className="commit_card">
                        <div className="commit_icon">
                          <img src="/assets/img/commitment.png" alt="" />
                        </div>
                        <h3>{item?.name}</h3>
                        <p>{item?.sub_title}</p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </section>
        {/* <!-- commmitment ends --> */}
        {/* <!-- gallery starts --> */}
        <section className="gallery_section">
          <div className="container">
            <div className="title_section">
              <div className="d-flex-jc-ac">
                <div className="col-lg-5 col-12 pe-lg-3 pe-0">
                  <h2>{navigationDataList?.gallery_json?.name}</h2>
                </div>
                <div className="col-lg-7 col-12">
                  <p className="text_just">
                    {navigationDataList?.gallery_json?.sub_title}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="gallerys">
            {navigationDataList?.gallery_json?.gallery_lists?.map((item, ind)=>{return(
              <div className="d-flex-jc-ac" key={ind}>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.top1_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.top1_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.top2_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.top2_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.top3_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.top3_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img src={item?.left_center} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.left_center}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img src={item?.center} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.center}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="gallery_card h_500">
                      <img src={item?.right_center} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.right_center}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.bottom1_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.bottom1_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.bottom2_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.bottom2_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="gallery_card h_300">
                      <img src={item?.bottom3_image} alt="" />
                      <div className="gallery_view">
                        <div className="org_btn">
                          <a
                            href={item?.bottom3_image}
                            className="image"
                            title=""
                          >
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              )})}
            </div> */}

            <div className="container">
              <div className="mis-tab mt-5">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  {navigationDataList?.gallery_json?.gallery_lists?.map(
                    (data, i) => (
                      <li
                        className="nav-item mb-2"
                        onClick={() => setGalleryimg(data)}
                        role="presentation"
                        key={i}
                      >
                        <span
                          role="button"
                          className={`nav-link ${
                            galleryimg.gallery_eng_name ==
                            data?.gallery_eng_name
                              ? "active"
                              : ""
                          } `}
                          data-bs-toggle="pill"
                        >
                          {languages == 2
                            ? data?.gallery_tamil_name
                            : data?.gallery_eng_name}
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                id="academics_img"
                className="tab-pane active dat_left holder mt-5"
              >
                <div className="gallerys">
                  <div className="d-flex-jc-ac">
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.top1_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.top1_image)}
                          >
                            {/* <a
                              href={galleryimg?.top1_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.top2_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.top2_image)}
                          >
                            {/* <a
                              href={galleryimg?.top2_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.top3_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.top3_image)}
                          >
                            {/* <a
                              href={galleryimg?.top3_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="gallery_card h_500">
                        <img src={galleryimg?.left_center} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.left_center)}
                          >
                            {/* <a
                              href={galleryimg?.left_center}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="gallery_card h_500">
                        <img src={galleryimg?.center} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.center)}
                          >
                            {/* <a
                              href={galleryimg?.center}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="gallery_card h_500">
                        <img src={galleryimg?.right_center} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.right_center)}
                          >
                            {/* <a
                              href={galleryimg?.right_center}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.bottom1_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.bottom1_image)}
                          >
                            {/* <a
                              href={galleryimg?.bottom1_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.bottom2_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.bottom2_image)}
                          >
                            {/* <a
                              href={galleryimg?.bottom2_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="gallery_card h_300">
                        <img src={galleryimg?.bottom3_image} alt="" />
                        <div className="gallery_view">
                          <div
                            className="org_btn"
                            onClick={() => getImg(galleryimg?.bottom3_image)}
                          >
                            {/* <a
                              href={galleryimg?.bottom3_image}
                              className="image"
                              title=""
                            > */}
                            <i className="fa-solid fa-magnifying-glass sr_ic"></i>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- gallery ends --> */}
        {/* <!-- thirukural starts --> */}
        {/* <section className="thirukural_section mar_100">
          <div className="thiru_img">
            <img
              src={
                thirukural_img
                  ? thirukural_img
                  : "/assets/img/thirukural_section.webp"
              }
              alt=""
            />
          </div>
          <div className="thiru_img d-lg-none d-block">
            <img src="/assets/img/thirukural_section_md.webp" alt="" />
          </div>
        </section> */}
        {/* <!-- thirukural ends --> */}

                {/* new thirukural section starts */}

     <section className="th_section">
          <div className="container">
            <div className="th_flex">
              <div className="col-md-4">
                <div className="thi_img">
                  <img src="/assets/img/thiruvaluvar_ic.webp" alt="" />
                </div>
              </div>
              <div className="col-md-8 ps-md-3 ps-0">
                <div className="th_cont">
                  <span>{thirukural?.THIRUKURAL_TITLE}</span>
                  <h3 dangerouslySetInnerHTML={{__html:thirukural?.THIRUKURAL}}></h3>
                  <p>{thirukural?.THIRUKURAL_DESC}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* new thirukural section ends */}
        
        {/* <!-- Other links starts --> */}
        <section className="other_links mar_100">
          <div className="container">
            <div className="title_section">
              <div className="d-flex-jc">
                <div className="col-lg-5 col-12 pe-lg-3 pe-0">
                  <h2>Quick links to other Departments</h2>
                </div>
                <div className="col-lg-7 col-12">
                  <p className="text_just">
                    Explore the vibrant journey of our students and school
                    events through this gallery. From classroom achievements to
                    extracurricular activities, witness the dedication and
                    growth at Tamil Nadu Government Schools. A glimpse into the
                    heart of education, fostering bright futures.
                  </p>
                  <div className="btn_new mt-4">
                    <a
                      href="https://nammaschool.tnschools.gov.in/#/"
                      target={"_blank"}
                    >
                      Donate to Namma School
                    </a>
                  </div>
                </div>
              </div>
            </div>

           {/* <div className="other_link_carso mt_50">
              <Slider {...quicklinks}>
                {navigationDataList?.quick_links?.map((img, i) => (
                  <div className="item" key={i}>
                    <a href={img?.redirect_url} target="_blank">
                      <div className="other_card">
                        <img src={img?.image_path} alt="" />
                      </div>
                    </a>
                  </div>
                ))}
                 <div className="item">
                  <a href="https://manarkeni.tnschools.gov.in/" target="_blank">
                    <div className="other_card">
                      <img src="/assets/img/Manarkenilogo-1.png" alt="" />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://nammaschool.tnschools.gov.in/#/"
                    target="_blank"
                  >
                    <div className="other_card">
                      <img src="/assets/img/NSLogo-final-1.png" alt="" />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://naanmudhalvan.tnschools.gov.in/home"
                    target="_blank"
                  >
                    <div className="other_card">
                      <img src="/assets/img/logo.13a0474-1.png" alt="" />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://illamthedikalvi.tnschools.gov.in/Welcome"
                    target="_blank"
                  >
                    <div className="other_card">
                      <img src="/assets/img/itklogo-1.png" alt="" />
                    </div>
                  </a>
                </div>
                <div className="item">
                  <a href="https://mozhigal.tnschools.gov.in/" target="_blank">
                    <div className="other_card">
                      <img src="/assets/img/header_logo-1.png" alt="" />
                    </div>
                  </a>
                </div> 
              </Slider>
            </div>
            */}
          </div>
        </section>

        {/* <!-- other links ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural={setThirukural} setThirukural_img={setThirukural_img} />
      </div>
    </>
  );
};

export default Home;
