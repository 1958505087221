import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import {
  navigationData,
  templeteDetailsData,
  tnSchoolCount,
} from "../constants/Services/Api/api";
import Footer from "../Layout/Footer";

const Directorates = () => {
  const location = useLocation();
  const languages = localStorage.getItem("language");
  const [navigationDataList, setNavigationDataList] = useState({});
  const [thirukural_img, setThirukural_img] = useState("");
  const [counts, setCounts] = useState({});

  // console.log('datadatadata',location?.state?.data?.id);
  // console.log('navigationDataListnavigationDataListnavigationDataList',navigationDataList);

  // console.log('location?.state?.data?.idlocation?.state?.data?.id',location?.state?.data?.id);
  
   
    const getSchoolCount = () => {
      tnSchoolCount()
        .then((res) => {
          // setCounts(res?.tnschools_count?.[0]);
          if(location?.state?.data?.id == 8){
            setCounts({
              count1 : res?.tnschools_count?.[0]?.DEE_school_count ?? '',
              count2 :  res?.tnschools_count?.[0]?.DEE_students ?? '',
              count3 :  res?.tnschools_count?.[0]?.DEE_teaching_staff_count ?? '',
            })
          }else if(location?.state?.data?.id == 69){
            setCounts({
              count1 : res?.tnschools_count?.[0]?.DSE_school_count ?? '',
              count2 : res?.tnschools_count?.[0]?.DSE_students ?? '',
              count3 : res?.tnschools_count?.[0]?.DSE_teaching_staff_count ?? '',
            })
          }else if(location?.state?.data?.id == 81){
            setCounts({
              count1 : res?.tnschools_count?.[0]?.DPS_school_count ?? '',
              count2 :  res?.tnschools_count?.[0]?.DPS_students ?? '',
              count3 :  res?.tnschools_count?.[0]?.DPS_teaching_staff_count ?? '',
            })
          }else {
            setCounts({})
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
  


    useEffect(() => {
      getSchoolCount();
    },[location?.state?.data?.id])

    // console.log('countscounts',counts);
    

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  const getTemplate = (navigation_slug, type) => {
    let payload = new FormData();
    payload.append("navigation_slug", navigation_slug);
    templeteDetailsData(payload)
      .then((res) => {
        let data = res?.response;
        let temp = { ...data };

        if (data?.banner_json) {
          let banner_json = { ...data?.banner_json };
          if (languages == 2) {
            banner_json.title = data?.banner_json?.tamil_title;
          } else {
            banner_json.title = data?.banner_json?.eng_title;
          }
          temp.banner_json = banner_json;
        }

        // TAB 2

        if (data?.about_json) {
          let history_json = { ...data?.about_json };
          let commitment_lists_ = Object.values(data?.about_json?.stats);
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
            } else {
              commitment_.title = item?.eng_title;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...history_json,
          };

          if (languages == 2) {
            commitment.title = data?.about_json?.tamil_title;
            commitment.about = data?.about_json?.tamil_about;
          } else {
            commitment.title = data?.about_json?.eng_title;
            commitment.about = data?.about_json?.eng_about;
          }

          commitment.stats = commitment_lists;
          temp.about_json = commitment;
        }

        if (data?.objective_json) {
          let history_json = { ...data?.objective_json };
          let commitment_lists_ = Object.values(
            data?.objective_json?.obj_points
          );
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.point = item?.tamil_point;
            } else {
              commitment_.point = item?.eng_point;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...history_json,
          };

          if (languages == 2) {
            commitment.title = data?.objective_json?.tamil_title;
            commitment.desc = data?.objective_json?.tamil_desc;
          } else {
            commitment.title = data?.objective_json?.eng_title;
            commitment.desc = data?.objective_json?.eng_desc;
          }

          commitment.obj_points = commitment_lists;
          temp.objective_json = commitment;
        }

        if (data?.program_json) {
          let history_json = {...data?.program_json };
          let commitment_lists_ = Object.values(data?.program_json?.program_lists);
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
              // commitment_.description = item?.tamil_description;
            } else {
              commitment_.title = item?.eng_title;
              // commitment_.description = item?.eng_description;
            }
            commitment_lists.push({...commitment_, programs : Object.values(item?.programs).map((fog, v) =>  {
              if(languages == 2){
                return {...fog,title:fog?.tamil_title, description:fog?.tamil_description}
              }else {
                return {...fog,title:fog?.eng_title, description:fog?.eng_description}
              }
            })});
          });
          let commitment = {
            ...history_json,
          };
          // if (languages == 2) {
          //   commitment.title = data?.program_json?.tamil_title;
          // } else {
          //   commitment.title = data?.program_json?.eng_title;
          // }

          commitment.program_lists = commitment_lists;
          temp.program_json = commitment;
          // console.log('commitmentcommitment',commitment_lists);
        }

        if (data?.service_offered_json) {
          let history_json = { ...data?.service_offered_json };
          let commitment_lists_ = Object.values(
            data?.service_offered_json?.programs
          );
          let commitment_lists = [];
          commitment_lists_?.map((item, ind) => {
            let commitment_ = { ...item };
            if (languages == 2) {
              commitment_.title = item?.tamil_title;
              commitment_.description = item?.tamil_description;
            } else {
              commitment_.title = item?.eng_title;
              commitment_.description = item?.eng_description;
            }
            commitment_lists.push(commitment_);
          });
          let commitment = {
            ...history_json,
          };

          if (languages == 2) {
            commitment.title = data?.service_offered_json?.tamil_title;
          } else {
            commitment.title = data?.service_offered_json?.eng_title;
          }

          commitment.programs = commitment_lists;
          temp.service_offered_json = commitment;
        }

        console.log("temp", temp);

        setNavigationDataList(temp);

        // to={item?.template_type == 2 ? "/about_us"
        //   :'/'
        //  }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getNavigation = () => {
    let payload = {};
    navigationData(payload)
      .then((res) => {
        let temp = [];
        res?.map((item, ind) => {
          item?.child_navigations?.map((chid, chind) => {
            temp.push(chid);
          });

          temp.push(item);
        });
        let finder = temp?.find((i) => i?.template_type == 3);
        if (finder?.id) {
          getTemplate(finder?.slug);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.slug) {
      getTemplate(location?.state?.data?.slug);
    } else {
      getNavigation();
    }
  }, [location?.state]);

  // console.log('navigationDataList', navigationDataList);

  return (
    <>
      <div className="main">
        {/* <!-- page banner starts --> */}

        <div className="page_banner">
          <img
            className="img_topcen"
            src={navigationDataList?.banner_json?.image_path}
            alt=""
          />
          <div className="page_content">
            <div className="text-center">
              <h1>
                {languages == 2
                  ? navigationDataList?.banner_json?.tamil_title
                  : navigationDataList?.banner_json?.eng_title}
              </h1>
            </div>
          </div>
        </div>

        {/* <!-- page banner ends --> */}

        {/* <!-- directorates section starts --> */}

        <div className=" mar_100 directortes_section">
          <div
            id="elementary_education"
            className="tab-pane active dat_left holder mt-3"
          >
            <div className="container">
              <div className="title_section">
                <h2>{navigationDataList?.about_json?.title}</h2>
                <p
                  className="text_just"
                  dangerouslySetInnerHTML={{
                    __html: navigationDataList?.about_json?.about,
                  }}
                ></p>
              </div>

              {
                Object.keys(counts).length > 0 ? 
                <div className="d-flex-jc">
                  <div
                    className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3"
                  >
                    <div className="count_card">
                      <div className="count_icon">
                        <img src="/assets/img/student_studying.png" alt="" />
                      </div>
                      <h3>{counts?.count1
                      ? commaValue(counts?.count1)
                      : 0}</h3>
                      <p>{'Schools throughout in Tamil Nadu'}</p>
                    </div>
                  </div>

                  <div
                    className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3"
                  >
                    <div className="count_card">
                      <div className="count_icon">
                        <img src="/assets/img/student_studying.png" alt="" />
                      </div>
                      <h3>{counts?.count2
                      ? commaValue(counts?.count2)
                      : 0}</h3>
                      <p>{'Students Studying Tamil Nadu'}</p>
                    </div>
                  </div>

                  <div
                    className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3"
                  >
                    <div className="count_card">
                      <div className="count_icon">
                        <img src="/assets/img/student_studying.png" alt="" />
                      </div>
                      <h3>{counts?.count3
                      ? commaValue(counts?.count3)
                      : 0}</h3>
                      <p>{'Teacher Working in Tamil Nadu'}</p>
                    </div>
                  </div>
                </div> :
              <div className="d-flex-jc"> 
                {
                navigationDataList?.about_json?.stats?.map((item, ind) => {
                  return (
                    <div
                      className="col-lg-4 col-sm-6 col-12 pe-sm-3 pe-0 mt-3"
                      key={ind}
                    >
                      <div className="count_card">
                        <div className="count_icon">
                          <img src="/assets/img/student_studying.png" alt="" />
                        </div>
                        <h3>{item?.stat_value}</h3>
                        <p>{item?.title}</p>
                      </div>
                    </div>
                  );
                })
                }
              </div>
              }

            </div>

            <div className="director_objectives mar_100">
              <div className="d-flex-jc-ac container">
                <div className="col-lg-6 col-12 pe-lg-5 pe-0 ">
                  <div className="title_section">
                    <h2>{navigationDataList?.objective_json?.title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: navigationDataList?.objective_json?.desc,
                      }}
                    ></p>
                  </div>
                  <div className="objective_list">
                    <ul>
                      {navigationDataList?.objective_json?.obj_points?.map(
                        (item, ind) => {
                          return (
                            <li key={ind}>
                              <i className="fa-solid fa-square-check txt_darkbl mt-1 me-2"></i>{" "}
                              {item?.point}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                  <img
                    className="w-100"
                    src={navigationDataList?.objective_json?.image_path}
                    alt=""
                  />
                </div>
              </div>
            </div>

            {
              navigationDataList?.program_json?.program_lists?.map((data, i) => (
                <div className="director_program mar_100 container">
                  <div className="acc_list">
                    <div className="title_section text-center">
                      <h2>{data?.title}</h2>
                    </div>
                    <Accordion defaultActiveKey="0">
                      {data?.programs?.map(
                        (item, ind) => {
                          return (
                            <Accordion.Item eventKey={ind}>
                              <Accordion.Header className="accordion-item ac-it">
                                <h2 className="accordion-header ac-he">
                                  {item?.title}
                                </h2>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="accordion-body ac-bo">
                                  <ol style={{ listStyleType: "none" }}>
                                    <li
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                      }}
                                    ></li>
                                  </ol>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        }
                      )}
                    </Accordion>
                  </div>
                </div>
              ))
              
            }

            <div className="director_program mar_100 container">
              <div className="acc_list">
                <div className="title_section text-center">
                  <h2>{navigationDataList?.service_offered_json?.title}</h2>
                </div>
                <Accordion defaultActiveKey="0">
                  {navigationDataList?.service_offered_json?.programs?.map(
                    (item, ind) => {
                      return (
                        <Accordion.Item eventKey={ind}>
                          <Accordion.Header className="accordion-item ac-it">
                            <h2 className="accordion-header ac-he">
                              {item?.title}
                            </h2>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-body ac-bo">
                              <ol style={{ listStyleType: "none" }}>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description,
                                  }}
                                ></li>
                              </ol>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    }
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- directorates section ends --> */}

        {/* <!-- footer section starts --> */}
        <Footer setThirukural_img={setThirukural_img} />
        {/* <!-- footer section ends --> */}
      </div>
    </>
  );
};

export default Directorates;
